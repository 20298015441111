import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { FilterCircleIcon, FoldIcon } from '../utils/icons';
import DirectusGateway from '../gateways/DirectusGateway';
import { IStoreGroup } from '../models/interfaces/IStoreGroup';
import { GroupFilter } from '../flow/OrderList';

export const showAll = "Show all";

interface DropDownProps {
  onDropDownChanged: Function;
}

interface DropDownState {
  filter: GroupFilter;
  title: string;
  open: boolean;
}

interface OptionProps {
  active?: boolean;
}

const DropDownWrapper = styled.div`
  position: absolute;
  width: ${isMobile ? "100%":"250px"};
`

const DropDownTop = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  border: solid 1px #0b5d91;
  width: ${isMobile ? "100%":"250px"};
  height: 38px;
  padding-left:8px;
  background-color: #FFF;
  svg{
    fill:#0b5d91;
  }
`

const Title = styled.div`
  display: flex;
  align-items: center;
  padding-left:8px;
  svg{
    padding-right: 20px;
    padding-top: 2px;
  }
`

const Options = styled.div`
  position: absolute;
  top: 36px;
  background-color: #FFF;
  border: solid 1px #0b5d91;
  width: ${isMobile ? "100%":"250px"};
  z-index: 10;
  padding-bottom: 16px;
  overflow: hidden;
  scroll-y: auto;
  max-height: calc(100vh - 100px);
`

const Option = styled.div<OptionProps>`
  padding: 6px 16px;
  margin-top:12px;
  font-size: 18px;
  background-color: #FFF;
  color:${props => props.active ? "#0b5d91":"#000"};
  font-weight:${props => props.active ? "bold":"normal"};
  cursor: pointer;
  transition: background-color 0.4s;
  &:hover{
    background-color: #e3f4fd;
  }
`

const SubOption = styled.div<OptionProps>`
  padding: 6px 16px;
  padding-left: 24px;
  font-size: 16px;
  color:${props => props.active ? "#0b5d91":"#000"};
  font-weight:${props => props.active ? "bold":"normal"};
  background-color: #FFF;
  cursor: pointer;
  transition: background-color 0.4s;
  &:hover{
    background-color: #e3f4fd;
  }
`
const FoldIconWrapper = styled.div<OptionProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-left: solid 1px #0b5d91;
  padding: 4px;
  width: 36px;
  height: 36px;
  float: right;
  background-color: ${props => props.active ? "#0b5d91":"#FFF"};
  svg path{
    fill:${props => props.active ? "#FFF" : "#0b5d91"} !important;
  }
`

class GroupStoreDropDown extends React.Component<DropDownProps, DropDownState>  {
  
  state: DropDownState = {
    filter: {group:showAll,store:null},
    open: false,
    title: showAll
  };

  bindFunc = function () { };

  close() {
    this.setState({ open: false });
    document.removeEventListener("click", this.bindFunc);
  }

  toggle() {
    if (!this.state.open) {
      this.bindFunc = this.close.bind(this);
      document.addEventListener("click", this.bindFunc);
    } else {
      document.removeEventListener("click", this.bindFunc);
    }
    this.setState({ open: !this.state.open });
    //TODO TIlføj noget event listener så den fjernes igen ved klik andet steds
  }

  filterForGroup(e: React.MouseEvent, filter:GroupFilter) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ filter});
    this.props.onDropDownChanged(filter);
    this.close();
  }

  render() {
    const groupStores = DirectusGateway.groupStores;
    return (
      <>
        <DropDownWrapper >
          <DropDownTop onClick={() => this.toggle()}>
            <Title><FilterCircleIcon />{this.state.filter.store || this.state.filter.group}</Title>
            <FoldIconWrapper active={this.state.open}>
              <FoldIcon active={this.state.open} />
            </FoldIconWrapper>
          </DropDownTop>
          {this.state.open &&
            <Options>
            <Option active={this.state.title === showAll} onClick={(e: React.MouseEvent) => this.filterForGroup(e, {group:showAll,store:null})}>{showAll}</Option>
              {groupStores.map((group: IStoreGroup, i) => {
                return (
                  <div key={i}>
                    <Option active={this.state.title === group.group} onClick={(e: React.MouseEvent) => this.filterForGroup(e, {group:group.group,store:null})}>{group.group}</Option>
                    {group.stores.map((store,j) => {
                      return (
                        <SubOption key={i*1000+j} active={this.state.title === store} onClick={(e: React.MouseEvent) => this.filterForGroup(e, {group:group.group,store})}>{store}</SubOption>
                      )
                    })
                    }
                  </div>
                );
              })}

            </Options>
          }
        </DropDownWrapper>
      </>
    );
  };
}

export default GroupStoreDropDown;