import styled from 'styled-components';

export const Column = styled.div`
    display: inline-block;
    overflow: hidden;
    :nth-child(1) {
        width: 10%;
    }
    :nth-child(2) {
        width: 10%;
    }
    :nth-child(3) {
        width: 10%;
    }
    :nth-child(4) {
        width: 10%;
    }
    :nth-child(5) {
        width: 10%;
    }
    :nth-child(6) {
    width: 10%;
    }
    :nth-child(7) {
    width: 8%;
    }
    :nth-child(8) {
    width: 15%;
    }
    :nth-child(9) {
    width: 10%;
    }
    :nth-child(10) {
    width: 5%;
    }
    :nth-child(11) {
        width: 2%;
        text-align: right;
    }
`


export const DialogWrapper = styled.div`
    position: fixed;
    left: 0px;
    top: 0px;
    width:100vw;
    height: 100vh;
    display: flex;    
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);
    z-index: 100;
`