export function rewrite(fields:string) {
    let result = fields.replace(/,/g,"&fields[]=");

    return "fields[]="+result;
}

export function makeSubFields(baseField:string, subFields:string) {
    let result = subFields.replace(/,/g,","+baseField+".");

    return baseField+"."+result+"";
}
