import React from 'react';
import styled from 'styled-components';
import { IOrderProcessItem } from '../../models/interfaces/IOrderProcessItem';
import Task from './Task';

const ListWrapper = styled.div`
  margin-top: 40px;
`

export interface TaskListProps {
  taskData: Array<IOrderProcessItem>;
  orderId: number;
  dataUpdated:Function;
  isArchived:boolean;
}

interface TaskListState {
}

export enum GroupStates {
  notInGroup,
  startGroup,
  insideGroup,
  endingGroup
}

class TaskList extends React.Component<TaskListProps, TaskListState> {

  state: TaskListState = {
  };

  async dataUpdated(){
    this.props.dataUpdated();
  }


  render() {
    const tasks = this.props.taskData.sort((a, b) => {
      if (!('position' in a.process_item_id)) return -1;
      if (a.process_item_id.position < b.process_item_id.position) {
        return -1;
      } else {
        return 1;
      }
    });

    let currentState = GroupStates.notInGroup;
    let currentGroup: string | null = "";

    return (
      <ListWrapper>
        {tasks.map((taskData, i) => {
          // Logger.log(taskData.process_item_id.process_group, currentGroup);

          if (!taskData.process_item_id.process_group) {
            currentState = GroupStates.notInGroup;
          } else if (!currentGroup && taskData.process_item_id.process_group !== currentGroup) {
            currentState = GroupStates.startGroup;
          } else if (currentGroup && taskData.process_item_id.process_group === currentGroup && tasks.length > (i + 1) && !tasks[i + 1].process_item_id.process_group) {
            currentState = GroupStates.endingGroup;
          } else if (tasks.length <= (i + 1)) {
            currentState = GroupStates.endingGroup;
          } else if (currentGroup && taskData.process_item_id.process_group === currentGroup) {
            currentState = GroupStates.insideGroup;
          }

          currentGroup = taskData.process_item_id.process_group;

          return (
            <Task key={i} orderId={this.props.orderId} processItem={taskData} isLast={this.props.taskData.length - 1 === i} groupState={currentState}  dataUpdated={()=>this.dataUpdated()} isArchived={this.props.isArchived}/>
          )
        })}
      </ListWrapper>

    );
  }
};

export default TaskList;