import Logger from "./Logger";

export function formatDateString(dateString: string): string {
    if (!dateString || dateString.length < 10) {
        return "";
    }
    const t = new Date(dateString);
    return formatDate(t);
}

export function formatDate(dateTime: Date): string {
    if (!dateTime) {
        return "";
    }
    try {
        const date = ('0' + dateTime.getDate()).slice(-2);
        const month = ('0' + (dateTime.getMonth() + 1)).slice(-2);
        const year = dateTime.getFullYear().toString().slice(-2);//
        // const hours = ('0' + t.getHours()).slice(-2);
        // const minutes = ('0' + t.getMinutes()).slice(-2);
        // const seconds = ('0' + t.getSeconds()).slice(-2);
        return `${date}.${month}.${year}`;
    } catch (error) {
        Logger.error(dateTime);
        return "";
    }
}

export function isAfterFromDateStrings(beforeStr: string, afterStr: string): boolean {
    if (!beforeStr || !afterStr) {
        return false;
    }
    try {
        const before = new Date(beforeStr);
        const after = new Date(afterStr);
        return isAfter(before, after);
    } catch (error) {
        return false;
    }
}

export function isAfter(before: Date, after: Date): boolean {
    return before.getTime() < after.getTime();
}


export function isAfterTodayFromDateStrings(beforeStr: string): boolean {
    if (!beforeStr) {
        return false;
    }
    try {
        const before = new Date(beforeStr);
        return isAfterToday(before);
    } catch (error) {
        return false;
    }
}

export function isAfterToday(before: Date): boolean {
    return isAfter(before, new Date());
}

export function addHours(date:Date,numOfHours: number) {
    var newdate = new Date();
    newdate.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
    return newdate;
}