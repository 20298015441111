import React from 'react';
import {
    HashRouter as Router,
    Switch,
    Route
} from 'react-router-dom';

import Login from './Login';
import OrderList from './flow/OrderList';

import NewOrderStandalone from './new-order/NewOrderStandalone';

import RequireAuth, { RequireServiceProviderAuth, RequireCustomerAuth } from './utils/RequireAuth';
import Reset from './Reset';

interface MainProps {
}

interface MainState {
    isLoggedIn: boolean;
    isServiceProvider: boolean;
}

class Main extends React.Component<MainProps, MainState> {
    state: MainState = {
        isLoggedIn: false,
        isServiceProvider: false
    }

    updateLoginState(newLogInState: boolean, isServiceProvider: boolean) {
        this.setState({
            isLoggedIn: newLogInState,
            isServiceProvider
        });
    }

    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/new-order">
                        <RequireAuth auth={this.state.isLoggedIn}>
                            <RequireCustomerAuth isUserCustomer={!this.state.isServiceProvider}>
                                <NewOrderStandalone />
                            </RequireCustomerAuth>
                        </RequireAuth>
                    </Route>
                    <Route path="/login">
                        <Login 
                            updateLogInState={(loggedIn: boolean, isCustomer: boolean) => this.updateLoginState(loggedIn, isCustomer)} 
                        />
                    </Route>
                    <Route path="/reset">
                        <Reset />
                    </Route>
                    <Route path="">
                        <RequireAuth auth={this.state.isLoggedIn}>
                            <RequireServiceProviderAuth isUserServiceProvider={this.state.isServiceProvider}>
                                <OrderList />
                            </RequireServiceProviderAuth>
                        </RequireAuth> 
                    </Route>
                </Switch>
            </Router>
        );
    }
}

export default Main;
