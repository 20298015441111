import styled from 'styled-components';

export const FilledButton = styled.div`
  display: flex;
  box-sizing: border-box;
  margin-left: 16px;
  height: 35px; 
  font-size: 14px;
  padding: 8px 16px 8px 16px;
  color: #ffffff;
  background-color: #0b5d91;
  cursor: pointer;
  svg{
    margin-right: 8px;
    position: relative;
    top: 1px;
  }
`

export const OutlineButton = styled.div`
  display: flex;
  box-sizing: border-box;
  margin-left: 16px;
  height: 35px; 
  font-size: 14px;
  padding: 8px 16px 8px 16px;
  background-color: #FFFFFF;
  color: #0b5d91;
  border: 1px solid #0b5d91;
  cursor: pointer;
  svg{
    margin-right: 8px;
    position: relative;
    top: 1px;
  }
`