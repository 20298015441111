import React from 'react';
import styled from 'styled-components';

import theme from '../theme';

const Input = styled.input`
  outline: none;
  
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.blue};
  box-sizing: border-box;
  
  min-width: 200px;
  min-height: 38px;
  padding: 8px 16px;
`;

interface InputfieldProps {
  disabled?:boolean;
  value: string;
  handleOnChange: (value: string) => void;
  placeholder?: string;
  numbersOnly?: boolean;
  style?: React.CSSProperties;
}

const Inputfield = ({ value, handleOnChange, placeholder, numbersOnly=false, style, disabled=false }: InputfieldProps) => {
  function onlyNumbers(str: string) {
    return str === "" ? true : /^[0-9]+$/.test(str);
  }
  if(!style){
    style = {};
  }
  
  if(numbersOnly){
    style.textAlign = "right";
  } 
  
  if(disabled){
    style.opacity = 0.5;
  }

  return (
    <Input
      disabled = {disabled}
      value={value}
      onChange={(event) => numbersOnly ? onlyNumbers(event.target.value) ? handleOnChange(event.target.value) : null : handleOnChange(event.target.value)}
      placeholder={placeholder}
      style={style}
    ></Input>
  );
}

export default Inputfield;
