const theme = {
  colors: {
    blue: "#0b5d91",
    lightBlue: "#f0f9ff",
    babyBlue: "#e3f4fd",
    babyBlue2: "#cddee9",
    babyBlue3: "#d6e5ef",
    black: "#000000",
    darkGrey: "#707070",
    grey: "#d1d1d1",
    white: "#FFFFFF",
    red: "#dd3333",
    orange: "#dd8833",
    green: "#008a00",
  },
  fontSizes: {

  }
}

export default theme;
