import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { FilledButton, OutlineButton } from './Buttons';
import { InfoCircleIcon } from '../utils/icons';
import { getStateTitle } from '../utils/States';
import { DialogWrapper } from '../utils/StyledComponents';

interface DialogProps {
  options: string[]
  value: string;
  title: string;
  closed:Function;
  submitted:Function;
}

interface DialogState {
  value: string;
}

const Dialog = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${isMobile ? "80vw":"580px"};
  padding:32px;
  background-color: #FFF;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.11);
`

const Caption = styled.div`
  margin-top:6px;
`
const DialogTitle = styled.div`
  margin-top:24px;
  color: #0b5d91;
  font-weight: bold;
`

const OptionsWrapper = styled.div`
  display: block;
  margin-top:32px;
`

const ButtonRow = styled.div`
  margin-top:40px;
  display: flex;
`

class StateDialog extends React.Component<DialogProps, DialogState>  {

  state: DialogState = {
    value: this.props.value
  };


  hideDialog() {
    this.props.closed();
  }

  updateStatus(status: string) {
    this.setState({
      value: status
    });
  }

  async submitDialog(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    this.props.submitted(this.state.value);
  }

  render() {
    return (
      <>
        <DialogWrapper onClick={() => this.hideDialog()} >
          <Dialog onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
            <InfoCircleIcon />
            <Caption>You are currently editing:</Caption>
            <DialogTitle>"{this.props.title}"</DialogTitle>
            <OptionsWrapper>
            {this.props.options.map((item, id) => {
              return (
                <div key={id}>
                  <input type="radio" name="drone" value={item} id={"input_" + item} onChange={() => this.updateStatus(item)} checked={item === this.state.value}/> <label htmlFor={"input_" + item}>{getStateTitle(item)}</label>
                </div>
              )
            })}
            </OptionsWrapper>
            <ButtonRow><OutlineButton onClick={(e: React.MouseEvent<HTMLDivElement>) => this.hideDialog()}>Cancel</OutlineButton><FilledButton onClick={(e: React.MouseEvent<HTMLDivElement>) => this.submitDialog(e)}>Complete</FilledButton></ButtonRow>
          </Dialog>
        </DialogWrapper>
      </>
    );
  };
}

export default StateDialog;