import React, { ComponentType } from 'react';
import styled from 'styled-components';
import { FilledButton } from './components/Buttons';
import DirectusGateway from './gateways/DirectusGateway';

import { withRouter, RouteComponentProps } from 'react-router-dom'

export interface LoginProps extends RouteComponentProps<any> {
    
}

interface LoginState {
    repeatPassword: string;
    password: string;
    error: string;
    showReciep: boolean;
}

const LoginWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 40px;
`

const LoginForm = styled.div`
    display: flex;
    flex-direction: column;
    width: 300px;
    padding:32px;
    background-color: #FFF;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.11);
`

const Label = styled.div`
`

const ErrorMessage = styled.div`
    padding: 3px 6px;
    color: #FFF;
    font-size: 12px;
    background-color: #d33;
    text-align: center;
    margin-bottom: 8px;
`

const TextInput = styled.input`    
    box-sizing: border-box;
    border: 1px solid #999;
    outline: none;
    width: 100%;
    padding:8px;
    &:focus{        
        outline: none;
    }
`

const InputBlock = styled.div`
    margin-bottom: 8px;
`

const SubmitBlock = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
    text-align: right;
    &>div{
        width:auto;
    }
`
const Back = styled.div`
    margin-bottom: 8px;
    text-align:right;
    font-size: 13px;
    cursor: pointer;
`


class Login extends React.Component<LoginProps, LoginState> {
    state: LoginState = {
        repeatPassword: "",
        password: "",
        error: "",
        showReciep: false
    };

    async componentDidMount() {
    }

    updatePassword(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            password: event.target.value,
            error: ""
        });
    }

    updateEmail(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            repeatPassword: event.target.value,
            error: ""
        });
    }


    async resetPassword() {
        if (this.state.password !== this.state.repeatPassword) {
            this.setState({
                error: "The two password are not identical."
            });
            return;
        }
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if (token) {
            const result = await DirectusGateway.resetPassword(this.state.password, token);
            if (result?.message === "NoData") {
                this.setState({
                    showReciep: true,
                    error: ""
                });
            }
            else {
                this.setState({ error: "Couldn't reset password." });
            }
        } else {
            this.setState({
                error: "Couldn't reset password. Please check your url."
            });
        }
    }

    render() {
        return (
            <>
                <LoginWrapper>
                    {this.state.showReciep ?
                        <LoginForm>
                            <div>
                                Your password has been updated.
                            </div>
                            <Back onClick={() => window.location.href = '/'}>
                                Back to login
                            </Back>
                        </LoginForm>
                        :
                        <LoginForm>
                            <InputBlock>
                                <Label>Password</Label>
                                <TextInput type="Password" value={this.state.repeatPassword} onChange={this.updateEmail.bind(this)} />
                            </InputBlock>
                            <InputBlock>
                                <Label>Repeat password</Label>
                                <TextInput type="password" value={this.state.password} onChange={this.updatePassword.bind(this)} />
                            </InputBlock>
                            {this.state.error &&
                                <ErrorMessage>{this.state.error}</ErrorMessage>
                            }
                            <SubmitBlock>
                                <FilledButton onClick={this.resetPassword.bind(this)}>Update password</FilledButton>
                            </SubmitBlock>
                        </LoginForm>
                    }
                </LoginWrapper>
            </>
        )
    };
}

export default withRouter<LoginProps, ComponentType<any>>(Login);