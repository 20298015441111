import React from 'react';
import styled from 'styled-components';
import theme from '../theme';

interface WrapperProps {
  useAltStyle: boolean;
}

const Wrapper = styled.button<WrapperProps>`
  background-color: ${props => props.useAltStyle ? "transparent" : theme.colors.blue};
  color: ${props => props.useAltStyle ? theme.colors.blue : theme.colors.white};
  border: 1px solid ${theme.colors.blue};
  padding: 8px 24px;
  font-size: 16px;
  
  &:hover {
    cursor: pointer;
    opacity: .8;
  }
`;

interface ButtonProps {
  children: React.ReactNode;
  handleOnClick: () => void;
  disabled?: boolean;
  useAlternativeStyle?: boolean;
  style?: React.CSSProperties;
}

const Button = ({ children, handleOnClick, disabled=false, useAlternativeStyle=false, style }: ButtonProps) => {
  return (
    <Wrapper 
      onClick={() => !disabled && handleOnClick()}
      useAltStyle={useAlternativeStyle} 
      style={disabled ? { ...style, cursor: "not-allowed" } : style}
    >
      {children}
    </Wrapper>
  );
}

export default Button;
