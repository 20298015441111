import React from 'react';
import styled from 'styled-components';
import theme from '../theme';

const HorizontalLine = styled.div`
  width: 100%;
  min-height: 1px;
  background-color: ${theme.colors.babyBlue3};
`;

interface SeperatorProps {
  margin?: number;
}

const Seperator = ({ margin = 0 }: SeperatorProps) => (
  <HorizontalLine 
    style={{
      marginTop: margin,
      marginBottom: margin
    }} 
  />
);

export default Seperator;