import React from 'react';
import styled from 'styled-components';

import theme from '../theme';

const TextareaComponent = styled.textarea`
  font-family: 'Open Sans', sans-serif;
  outline: none;
  resize: none;
  padding: 8px 16px;
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.blue};
  font-size: 16px;
`;

interface TextareaProps {
  value: string;
  handleOnChange: (value: string) => void;
  placeholder?: string;
  style?: React.CSSProperties;
}

const Textarea = ({ value, handleOnChange, placeholder, style }: TextareaProps) => {
  return (
    <TextareaComponent
      value={value}
      onChange={(event) => handleOnChange(event.target.value)}
      placeholder={placeholder}
      style={style}
    ></TextareaComponent>
  );
}

export default Textarea;
