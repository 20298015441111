import React from 'react';
import styled from 'styled-components';

interface DialogProps {
  text: string;
  color?: string;
  background?: string;
}

interface ColorProp {
  color: string;
}

interface SpinnerProps extends ColorProp {
  background: string;
}

const SpinnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
`

const SpinnerText = styled.div<ColorProp>`
  color: ${props => props.color};
  font-weight: 600;
  font-size: 14px;
`

const Spinner = styled.div<SpinnerProps>`
  &,
  &:before,
  &:after {
    border-radius: 50%;
  }
  & {
    color: ${props => props.color};
    font-size: 11px;
    text-indent: -99999em;
    margin-bottom: 12px;
    position: relative;
    width: 5em;
    height: 5em;
    box-shadow: inset 0 0 0 .5em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  &:before,
  &:after {
    position: absolute;
    content: '';
  }
  &:before {
    width: 2.7em;
    height: 5.2em;
    background: ${props => props.background};
    border-radius: 5.2em 0 0 5.2em;
    top: -0.1em;
    left: -0.1em;
    -webkit-transform-origin: 2.6em 2.6em;
    transform-origin: 2.6em 2.6em;
    -webkit-animation: load2 2s infinite ease 1.5s;
    animation: load2 2s infinite ease 1.5s;
  }
  &:after {
    width: 2.7em;
    height: 5.2em;
    background: ${props => props.background};
    border-radius: 0 5.2em 5.2em 0;
    top: -0.1em;
    left: 2.45em;
    -webkit-transform-origin: 0.1em 2.6em;
    transform-origin: 0.1em 2.6em;
    -webkit-animation: load2 2s infinite ease;
    animation: load2 2s infinite ease;
  }
  @-webkit-keyframes load2 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load2 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`

const Loader = ({ text, color="#a6dcf7", background="#ffffff" }: DialogProps) => (
  <>      
    <SpinnerWrapper>
      <Spinner color={color} background={background} className="loader"></Spinner>
      <SpinnerText color={color}>{text}</SpinnerText>
    </SpinnerWrapper>
  </>
);

export default Loader;