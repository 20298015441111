import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useOnClickOutside } from 'usehooks-ts';

import theme from '../theme';

import { IStore } from '../../models/interfaces/IStore';

import { SearchIcon } from '../assets/icons';

interface WrapperProps { 
	active: boolean;
}

const Wrapper = styled.div<WrapperProps>`
	display: flex;
	flex-direction: row;
	align-items: center;

	position: relative;

	box-sizing: border-box;
	height: 40px;
	padding: 8px 16px;
	background-color: ${theme.colors.white};
	border: 1px solid ${theme.colors.blue};
`;

const SearchIconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding-right: 8px;
`;

const Input = styled.input`
  border: none;
  outline: none;

	flex: 1;
`;

const SearchResultsContainer = styled.div`
	position: absolute;
	top: 44px;
	left: -1px; // Account for the border
	z-index: 1;

	display: flex;
	flex-direction: column;
	
	overflow-y: auto;
	max-height: 400px;
	box-sizing: border-box;
	border: 1px solid ${theme.colors.blue};
`;

const SearchResult = styled.div`
	box-sizing: border-box;
	width: 100%;
	background-color: ${theme.colors.white};
	padding: 8px 16px;

	&:hover {
		background-color: ${theme.colors.babyBlue};
	}
`;

interface StoreSearchbarProps {
  value: string;
	handleOnChange: (value: string) => void;
  handleSelectStore: (store: IStore) => void;
	searchResults: IStore[];
	width?: string;
  placeholder?: string;
  style?: React.CSSProperties;
}

const StoreSearchbar = ({ value, handleOnChange, handleSelectStore, searchResults, width="400px", placeholder, style }: StoreSearchbarProps) => {
	const [ active, setActive ] = useState(false);

	const ref = useRef(null);

	useOnClickOutside(ref, () => setActive(false));

	const storeToPrettyString = (store: IStore) => {
		let strBuilder: string[] = [];
		if (store.name) strBuilder.push(store.name);
		if (store.address) strBuilder.push(store.address);
		if (store.city) strBuilder.push(store.city);
		if (store.zip) strBuilder.push(store.zip);
		if (store.internal_id) strBuilder.push(store.internal_id.toString());
		
		return strBuilder.join(", ");
	}

  return (
		<Wrapper 
			active={active} 
			ref={ref}
			style={{ ...style, width }}
		>
			<SearchIconContainer>
				<SearchIcon />
			</SearchIconContainer>
			<Input
				value={value}
				onChange={(event) => handleOnChange(event.target.value)}
				placeholder={placeholder}
				onFocus={() => setActive(true)}
			></Input>

			{active && value.length > 0 && (
				<SearchResultsContainer style={{ width }}>
					{searchResults.filter(result => storeToPrettyString(result).toUpperCase().includes(value.toUpperCase())).map((result) => (
						<SearchResult 
							key={result.id}
							onClick={() => {
								handleSelectStore(result);
								handleOnChange(storeToPrettyString(result))
								setActive(false);
							}}
						>
							{storeToPrettyString(result)}
						</SearchResult>
					))}
				</SearchResultsContainer>
			)}
		</Wrapper>
  );
}

export default StoreSearchbar;
