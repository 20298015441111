export enum logLevel {
    LOG_NONE = 0,
    LOG_ERROR = 1,
    LOG_ERROR_WARN = 2,
    LOG_ERROR_WARN_INFO = 3,
    LOG_ALL = 4,
}

export interface ILoggerPlugin {
    execute: Function;
}

class Logger {
    stopwatchStartTime = null;
    gotConsole = true;
    level: logLevel;

    private _loggerPlugins: Array<ILoggerPlugin> = [];

    constructor() {
        this.level = (window.eastWestSettings === undefined) ? logLevel.LOG_ALL : window.eastWestSettings.logLevel;
    }

    /**
    * Log-er en tekst ud hvis browseren understøtter dette
    *@method log
    *@param {Object} message Besked der skal logges
    */
    log(...messages: Array<any>) {
        if (this.level < logLevel.LOG_ALL) return;
        this.doAllLogs(messages, "log");
    };

    /**
    * Log-er en tekst ud som advarsel hvis browseren understøtter dette
    *@method trace
    *@param {Object} message Besked der skal logges
    */
    trace(...messages: Array<any>) {
        if (this.level < logLevel.LOG_ALL) return;
        this.doAllLogs(messages, "trace");
    }

    /**
    *  Svarer til den tilsvarende console funktionalitet
    *@method debug
    *@param {Object} message Besked der skal logges
    */
    debug(...messages: Array<any>) {
        if (this.level < logLevel.LOG_ALL) return;
        this.doAllLogs(messages, "debug");
    }

    /**
    * Svarer til den tilsvarende console funktionalitet
    *@method info
    *@param {Object} message Besked der skal logges
    */
    info(...messages: Array<any>) {
        if (this.level < logLevel.LOG_ERROR_WARN_INFO) return;
        this.doAllLogs(messages, "info");
    };
    /**
    * Log-er en tekst ud som advarsel hvis browseren understøtter dette
    *@method warn
    *@param {Object} message Besked der skal logges
    */
    warn(...messages: Array<any>) {
        if (this.level < logLevel.LOG_ERROR_WARN) return;
        this.doAllLogs(messages, "warn");
    };
    /**
    * Log-er en tekst ud som fejl hvis browseren understøtter dette
    *@method error
    *@param {Object} message Besked der skal logges
    */
    error(...messages: Array<any>) {
        if (this.level < logLevel.LOG_ERROR) return;
        this.doAllLogs(messages, "error");
        // loggerGateway.error("Guide: " + messages);
    };

    groupCollapsed(msg: string) {
        if (this.level === logLevel.LOG_NONE) return;
        console.groupCollapsed(msg);
    }

    group(msg: string) {
        if (this.level === logLevel.LOG_NONE) return;
        console.group(msg);
    }

    groupEnd() {
        if (this.level === logLevel.LOG_NONE) return;
        console.groupEnd();
    }

    private doAllLogs(messages: Array<any>, type: string) {
        if (this.gotConsole) {
            // @ts-ignore
            if (typeof console[type] !== "undefined") {
                try {
                    // @ts-ignore
                    console[type](...messages);
                } catch (error) {
                    console.log(error);
                }
            } else {
                console.log.apply(messages);
            }
        }
        this.executePlugins(messages, type);
    }

    addLogPlugin(pluginFunction: ILoggerPlugin) {
        this._loggerPlugins.push(pluginFunction);
    }

    private executePlugins(messages: Array<any>, type: string) {
        this._loggerPlugins.forEach((fn) => {
            fn.execute(messages, type);
        });
    };

}

export default new Logger();