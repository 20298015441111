import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useOnClickOutside } from 'usehooks-ts';

import theme from '../theme';

import { Chevron } from '../assets/icons';

const Select = styled.div`
	position: relative;

	display: flex;
	flex-direction: row;

	height: 38px;
	max-height: 38px;
	background-color: ${theme.colors.white};
	border: 1px solid ${theme.colors.blue};

	&:hover {
		cursor: pointer;
	}
`;

const ValueContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	
	flex: 1;
	padding: 8px 16px;
	overflow: hidden;
`;

const ArrowContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	
	width: 36px;
	height: 36px;
	border-left: 1px solid ${theme.colors.blue};
`;

interface ArrowAnimationContainerProps {
	flipped: boolean;
}

const ArrowAnimationContainer = styled.div<ArrowAnimationContainerProps>`
	transform: ${props => props.flipped ? "rotateZ(-180deg)" : "rotateZ(0deg)"};
	transition: transform .25s;
`;

const OptionsContainer = styled.div`
	position: absolute;
	top: 44px;
	left: -1px; // Account for the border
	z-index: 1;

	display: flex;
	flex-direction: column;

	border: 1px solid ${theme.colors.blue};
`;

const Option = styled.div`
	box-sizing: border-box;
	width: 100%;
	background-color: ${theme.colors.white};
	padding: 8px 16px;

	&:hover {
		background-color: ${theme.colors.lightBlue};
	}
`;

interface DropdownOption {
	key:string;
	value:string;
}

interface DropdownProps {
	value: string;
	handleOnChange: (value: string) => void;
	options: Array<DropdownOption>;
	width?: number;
	style?: React.CSSProperties;
}

/**
 * Drop down, men uden tranlation logik som findes i DropDown, der er deprecated
 */
const NewDropdown = ({ value, handleOnChange, options, width = 200, style }: DropdownProps) => {
	const [active, setActive] = useState(false);

	const ref = useRef(null);

	useOnClickOutside(ref, () => setActive(false));
	const shownValue = options.find((opt)=> opt.key === value);

	return (
		<Select
			onClick={() => setActive(!active)}
			ref={ref}
			style={{ ...style, width }}
		>
			<ValueContainer>
				{shownValue?.value ?? value}
			</ValueContainer>
			<ArrowContainer>
				<ArrowAnimationContainer flipped={active}>
					<Chevron />
				</ArrowAnimationContainer>
			</ArrowContainer>
			{active && (
				<OptionsContainer>
					{options.map((option, index) => (
						<Option
							key={index}
							onClick={() => handleOnChange(option.key)}
						>
							{option.value}
						</Option>
					))}
				</OptionsContainer>
			)}
		</Select>
	);
}

export default NewDropdown;
