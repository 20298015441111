import React from 'react';
import styled from 'styled-components';
import RichTextEditor, { EditorValue } from 'react-rte';
import { isMobile } from 'react-device-detect';
import { OutlineButton, FilledButton } from '../../components/Buttons';
import DirectusGateway from '../../gateways/DirectusGateway';
import { IOrderProcessItem } from '../../models/interfaces/IOrderProcessItem';
import { CalendarIcon, CircleIcon, EditCircleIcon } from '../../utils/icons';
import { getStateColor, getStateTitle } from '../../utils/States';
import StateDialog from '../../components/StateDialog';
import { GroupStates } from './TaskList';
import ShowMoreText from "react-show-more-text";
import { isAfterTodayFromDateStrings, formatDateString } from '../../utils/Dates';
import DatePicker from 'react-date-picker';



interface TaskProps {
  processItem: IOrderProcessItem;
  orderId: number;
  isLast: boolean;
  groupState: GroupStates;
  dataUpdated: Function;
  isArchived: boolean;
}

interface TaskState {
  showStateDialog: boolean;
  status: string;
  editNote: boolean;
  note: EditorValue;
  editDeadline: boolean;
  deadline: Date | null;
}

interface ColorProps {
  background: string;
  text: string;
}

interface DeadlineProps {
  isDue: boolean;
}

interface StateProps {
  state: GroupStates;
}

const TaskWrapper = styled.div`
  display: flex;
  min-height: 150px;
`

const LeftColumn = styled.div`
  padding-top: 8px;
  width: 120px;
  flex: 0 0 120px;
  text-align: right;
`

const Tag = styled.div<ColorProps>`
  font-weight: bold;
  background-color: ${props => props.background};
  color: ${props => props.text};
  padding: 0 8px;
  margin-bottom:8px;
  display: inline-block;
  text-transform: uppercase;
  font-size: 15px;
`
const MobileTags = styled.div`
  div{
    margin-right: 8px;
  }
`

const MiddleColumn = styled.div<StateProps>`
  background-color: ${props => (props.state === GroupStates.insideGroup || props.state === GroupStates.startGroup) ? "#bce5ff" : "unset"};
  width: 27px;
  flex: 0 0 27px;
  margin: 0 3px;
  position: relative;
  margin:0  8px;
`

const RightColumn = styled.div`
flex: 1;
  padding-top: 8px;
  flex-grow: 1;
  padding-left:  ${isMobile ?"8px":""};
`

const Line = styled.div`
  position: absolute;
  left: 13px;
  height: 100%;
  width: 1px;
  background-color: #000;
`

const CircleWrapper = styled.div<StateProps>`
  box-sizing: border-box;
  position: absolute;
  height: 40px;
  width: 27px;
  background-color: #f0f9ff;
  z-index: 10;
`

const Note = styled.div`
  margin-top: 8px;
  width: ${isMobile ? "auto" : "550px"};
  padding-left:  ${isMobile ?"":"16px"};
  a.my-anchor-css-class{
    display:block;
    font-weight: bold;
    font-style: italic;
    font-size:12px;
  }
`
const ShowMoreContent = styled.div`
`

const TitleWrapper = styled.div`
  display: ${isMobile ? "block" : "flex"};
  &>svg{
    margin-left: 16px;
  }
`

const Title = styled.span`
  display: ${isMobile ? "block" : "unset"};
  font-weight: bold;
`

const Deadline = styled.span<DeadlineProps>`  
  font-size: 14px;
  font-style: italic;
  border-bottom: 1px solid #000;
  margin-left:  ${isMobile ?"":"16px"};
  color: ${props => (props.isDue) ? "#d33" : "#000"};
  
  font-weight: ${props => (props.isDue) ? "bold" : "normal"};
  border-color: ${props => (props.isDue) ? "#d33" : "#000"};
  path{
    fill:${props => (props.isDue) ? "#d33" : "#000"};
  }
`

const EditWrapper = styled.div`
  margin-top: 8px;
  width: 566px;
  height: 450px;
  &>div{    
    height: 310px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    width:100%;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  box-sizing: border-box;
  background-color: #FFF;
  border: 1px solid #ddd;
  border-top:none;
  height: auto !important;
`
//https://www.npmjs.com/package/react-date-picker
const DatePickerWrapper = styled.div`
margin-top: 16px;

  .react-date-picker {
    z-index:11;
  }

  .react-calendar{
    background-color: #FFF;
  }

  .react-date-picker__wrapper{
    background-color: #FFF;
  }

  .react-date-picker__calendar-button{
    
  }

  .react-date-picker__wrapper{
    padding: 2px 6px;
    border-color: #ddd;
    margin-left: 4px;
  }

  .react-calendar__tile--active{
    background: #0b5d91;
    color: white;
  }

  .react-calendar__tile--now{
    background: #e3f4fd
  }
  
  .react-date-picker__button {
    transform: scale(0.8);
  }

  .react-date-picker__inputGroup__input{
    &:invalid{
      background: rgb(240 249 255);
    }
    &:focus{
      outline: none;
    }
  }
`


class Task extends React.Component<TaskProps, TaskState>  {

  constructor(props: TaskProps) {
    super(props);

    let note: EditorValue = RichTextEditor.createValueFromString(this.props.processItem.note || "<p></p>", "html");
    const dueDate = props.processItem.due_date;
    this.state = {
      showStateDialog: false,
      status: '',
      editNote: false,
      note: note,
      editDeadline: false,
      deadline: dueDate ? new Date(dueDate) : null
    };
  }

  setStatus() {
    this.setState({
      showStateDialog: true
    });
  }

  startEdit() {
    this.setState({
      editNote: true
    });
  }

  async stopEdit() {
    this.setState({
      editNote: false
    });

  }

  async submitNote() {
    this.setState({
      editNote: false
    });

    DirectusGateway.updateProcessNote(this.props.processItem.id, this.props.orderId, this.state.note.toString('html'), this.state.deadline);

    this.props.dataUpdated();
  }

  updateEdit(value: EditorValue) {
    this.setState({ note: value });
  }

  hideDialog() {
    this.setState({
      showStateDialog: false
    });
  }

  async updateState(state: string) {
    this.setState({
      showStateDialog: false
    });
    if(state === this.props.processItem.status){
      return;
    }
    DirectusGateway.updateProcessState(this.props.processItem.id, this.props.orderId, state);
    this.props.dataUpdated();
    if(this.isDone(state) && this.props.processItem.process_item_id.notification_email?.length >= 5){
      DirectusGateway.sendCompletedMail(this.props.processItem, this.props.orderId);
    }
  }

  toggleShowMore() {

  }

  isDone(state:string){
    return (state.toLowerCase() === "done");
  }

  deadlineChange(deadline: Date | null) {
    if(deadline){
      deadline.setHours(12);//Der er et konverterings problem (locale til utc), da der kun vælges dato. Derfor lægges der lidt ekstra timer til.
    }
    this.setState({ deadline });
  }

  available_process_item_statuses: string[] = ["pending"].concat(this.props.processItem.process_item_id.ongoing ? ["ongoing"] : []).concat(["done"]);

  render() {
    let state = this.props.processItem.status;
    const toolbarConfig = {
      display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'HISTORY_BUTTONS'],
      INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD' },
        { label: 'Italic', style: 'ITALIC' }
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' }
      ]
    };

    return (
      <>
        <TaskWrapper>
          {!isMobile &&
            <LeftColumn>
              <Tag background={this.props.processItem.process_item_id.service_partner_id.color + ""} text='#FFF'>{this.props.processItem.process_item_id.service_partner_id.name}</Tag>
              <br />
              <Tag background={getStateColor(state)} text='#FFF' onClick={() => { !this.props.isArchived && this.setStatus() }} style={{ cursor: "pointer" }}>{getStateTitle(state)}</Tag>
            </LeftColumn>
          }
          <MiddleColumn state={this.props.groupState}>
            <CircleWrapper state={this.props.groupState}>
              <CircleIcon active={this.isDone(state)} handleOnClick={() => { !this.props.isArchived && this.setStatus() }} groupState={this.props.groupState} />
            </CircleWrapper>
            {!this.props.isLast &&
              <Line />
            }
          </MiddleColumn>
          <RightColumn>
            {isMobile &&
              <MobileTags>
                <Tag background={this.props.processItem.process_item_id.service_partner_id.color + ""} text='#FFF'>{this.props.processItem.process_item_id.service_partner_id.name}</Tag>
                <Tag background={getStateColor(state)} text='#FFF' onClick={() => { !this.props.isArchived && this.setStatus() }} style={{ cursor: "pointer" }}>{getStateTitle(state)}</Tag>
              </MobileTags>
            }
            <TitleWrapper>
              <Title>{this.props.processItem.process_item_id.name}</Title>
              {this.props.processItem.due_date &&
                <Deadline isDue={state !== "done" && isAfterTodayFromDateStrings(this.props.processItem.due_date)}><CalendarIcon /> Deadline: <i>{formatDateString(this.props.processItem.due_date)}</i></Deadline>
              }

              {!this.state.editNote && !this.props.isArchived && !isMobile &&
                <EditCircleIcon handleOnClick={() => this.startEdit()} />}
            </TitleWrapper>
            {this.state.editNote ?
              <>
                <DatePickerWrapper>Deadline (optional):
                  <DatePicker onChange={(e: Date) => this.deadlineChange(e)} value={this.state.deadline} format="dd.MM.yyyy" dayPlaceholder="DD" monthPlaceholder="MM" yearPlaceholder="YYYY" />
                </DatePickerWrapper>
                <EditWrapper>
                  {/*@ts-ignore*/}
                  <RichTextEditor value={this.state.note} onChange={(value: EditorValue) => this.updateEdit(value)} toolbarConfig={toolbarConfig} />
                  <ButtonWrapper><OutlineButton onClick={() => { this.stopEdit() }}>Cancel</OutlineButton> <FilledButton onClick={() => { this.submitNote() }}>Done</FilledButton></ButtonWrapper>
                </EditWrapper>
              </>
              :
              <Note className="note">
                <ShowMoreText
                  lines={3}
                  more="Show more"
                  less="Show less"
                  className="content-css"
                  anchorClass="my-anchor-css-class"
                  onClick={this.toggleShowMore.bind(this)}
                  expanded={false}
                  truncatedEndingComponent={"... "}
                >
                  <ShowMoreContent dangerouslySetInnerHTML={{ __html: this.state.note.toString('html') }} />
                </ShowMoreText>
              </Note>
            }
          </RightColumn>
        </TaskWrapper>
        {this.state.showStateDialog &&
          <StateDialog options={this.available_process_item_statuses} value={this.props.processItem.status} title={this.props.processItem.process_item_id.name}
            closed={() => this.hideDialog()} submitted={(value: string) => this.updateState(value)} />
        }
      </>
    );
  };
}

export default Task;
