import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { FilledButton } from './Buttons';
import { DownloadIcon } from '../utils/icons';
import DirectusGateway from '../gateways/DirectusGateway';
import { Filters } from '../utils/Enums';
import GroupStoreDropDown from './GroupStoreDropDown';

interface TopProps {
  onStateFilterChanged: Function;
  onGroupFilterChanged: Function;
  onNewOrder: Function;
}

const TopWrapper = styled.div`
  position:relative;
  display: flex;
  width:100%;
  justify-content: space-between;
  padding: 10px ${isMobile ? "20px" : "40px"};
  margin-top:30px;
`

const Filter = styled.div`
  position:relative;
  width: ${isMobile ? "100%" : "250px"};
  height: ${isMobile ? "80px" : "90px"};
`

const FilterHeader = styled.div`
  height: 30px;
  font-weight: bold;
  font-size: 16px;
`

const Logout = styled.div`
  height: 30px;
  cursor: pointer;
  font-size: 16px;
  color:#000;
`

const LogoutMobile = styled.div`
  position:absolute;
  right:16px;
  top:-16px;
  cursor: pointer;
  font-size: 16px;
  color:#000;
`

const Right = styled.div`
  display: flex;    
  flex-direction: column;
  align-items: flex-end;
  *>{
    margin-left: 20px;
  }
`

const Left = styled.div`
  display: flex;
  width: ${isMobile ? "100%" : "none"};
  flex-direction: ${isMobile ? "column" : "row"};
  *>{
    margin-right: 20px;
  }
`

const ArchivedCB = styled.div`
  padding-top: ${isMobile ? "0px" : "30px"};
  padding-left:  ${isMobile ? "0px" : "40px"};
  display: flex;
  input{
    height: 32px;
  }
  label{
    line-height: 36px;
  }
`
class Top extends React.Component<TopProps> {

  stateFilterState = Filters.Active;

  logOut() {
    DirectusGateway.reset();
  }

  stateFilterChanged(data: Filters) {
    this.stateFilterState = data;
    this.props.onStateFilterChanged(data);
  }

  storeFilterChanged(data: Array<string>) {
    this.props.onGroupFilterChanged(data);
  }

  render() {
    return (
      <TopWrapper>
        {isMobile && !DirectusGateway.isInSharePoint &&
          <LogoutMobile onClick={this.logOut.bind(this)}>Log out</LogoutMobile>
        }
        <Left>
          {DirectusGateway.customerIds.length !== 0 &&
            <>
              <Filter>
                {/* <StateDropDown onDropDownChanged={(data: Filters) => this.stateFilterChanged(data)} /> */}
                <FilterHeader>Store filter</FilterHeader>

                <GroupStoreDropDown onDropDownChanged={(data: Array<string>) => this.storeFilterChanged(data)} />
              </Filter>
              <ArchivedCB>
                <input type="checkbox" onChange={() => { this.stateFilterChanged((this.stateFilterState === Filters.Archive) ? Filters.Active : Filters.Archive) }} id="ArchivedToggle" />
                <label htmlFor="ArchivedToggle" style={{ paddingLeft: "5px" }}> Show archived</label>
              </ArchivedCB>
            </>
          }
        </Left>
        <Right>
          {!isMobile && !DirectusGateway.isInSharePoint &&
            <Logout onClick={this.logOut.bind(this)}>Log out ({DirectusGateway.userName})</Logout>
          }
          {DirectusGateway.customerIds.length !== 0 &&
            <>
              {!isMobile &&
                <FilledButton onClick={() => this.props.onNewOrder()} style={{ height: 38, lineHeight: '22px' }}><DownloadIcon style={{ top: '4px' }} /> New order</FilledButton>
              }
            </>
          }
        </Right>
      </TopWrapper>
    );
  }
}

export default Top;