import React from 'react';
import styled from 'styled-components';

const Text = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  text-align: right;
`;

interface LabelProps {
  children: string;
  style?: React.CSSProperties;
}

const Label = ({ children, style }: LabelProps) => {
  return (
    <Text style={style}>
      {children}
    </Text>
  );
}

export default Label;