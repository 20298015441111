import React from 'react';
import styled from 'styled-components';

import NewOrder from './NewOrder';

import Label from './components/Label';

import DirectusGateway from '../gateways/DirectusGateway';

import theme from './theme';
import T from './assets/translations';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100vh;
`;

const NewOrderWrapper = styled.div`
  width: 80vw;
  max-width: 800px;
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 80vw;
  max-width: 800px;
  padding: 16px 0px;
`;

const LogoutLabel = styled.div`
  cursor: pointer;
  font-weight: bold;
  color: ${theme.colors.darkGrey};
`;

const NewOrderStandalone = () => (
  <Wrapper>
    <TopWrapper>
      <Label>{`${T.LOGGED_IN_AS}${DirectusGateway.userName}`}</Label>
      <LogoutLabel onClick={() => DirectusGateway.reset()}>
        <Label style={{ fontSize: 14 }}>{T.LOGOUT}</Label>
      </LogoutLabel>
    </TopWrapper>
    <NewOrderWrapper>
      <NewOrder />
    </NewOrderWrapper>
  </Wrapper>
);

export default NewOrderStandalone;