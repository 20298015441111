import React from 'react';
import { GroupStates } from '../flow/tasks/TaskList';

interface ToggleIconProps {
    active?: boolean;
}

interface IconProps {
    style?: React.CSSProperties;
}

interface ClickIconProps {
    handleOnClick: Function;
}

interface CircleIconProps extends ClickIconProps, ToggleIconProps {
    groupState: GroupStates;
}


export const CircleIcon = ({ active = false, handleOnClick, groupState }: CircleIconProps) => {
    return (
        <svg width="27" height="40" viewBox="0 0 27 40" onClick={() => handleOnClick()} style={{ cursor: "pointer" }}>

            {groupState === GroupStates.startGroup &&
                <>
                    <rect width="27" height="20" x="0" y="20" fill="#bce5ff" />
                    <circle cx="13.5" cy="20" r="13.5" fill="#bce5ff" />
                </>
            }
            {groupState === GroupStates.endingGroup &&
                <>
                    <rect width="27" height="20" x="0" y="0" fill="#bce5ff" />
                    <circle cx="13.5" cy="20" r="13.5" fill="#bce5ff" />
                </>
            }
            
            {groupState === GroupStates.insideGroup &&
                <>
                    <rect width="27" height="40" x="0" y="0" fill="#bce5ff" />
                </>
            }
            {active ?
                <>
                    <circle cx="13.5" cy="20" r="8.5" fill="#FFFFFF" stroke='#000000' strokeWidth="1" />
                    <circle cx="13.5" cy="20" r="5" fill="#000000" stroke='#FFFFFF' strokeWidth="1" />
                </>
                :
                <circle cx="13.5" cy="20" r="8.5" fill="#FFFFFF" stroke='#000000' strokeWidth="1" />
            }

        </svg>
    )
};

export const FoldIcon = ({ active = false }: ToggleIconProps) => {
    return active ?
        <svg xmlns="http://www.w3.org/2000/svg" width="14.999" height="9" viewBox="0 0 14.999 9">
            <path fill="#000000" d="M7.5 2.572 1.25 9 0 7.715l6.25-6.428L7.5.001 15 7.715l-1.25 1.286z" />
        </svg>
        :
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="9" viewBox="0 0 15 9">
            <path fill="#000000" d="M6.25 7.715 0 1.287 1.25.001 7.5 6.43 13.75.002 15 1.287 7.5 9.001z" />
        </svg>
};

export const DownloadIcon =  ({ style }: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="13" viewBox="0 0 10 13" style={style}>
            <path fill="#FFFFFF" d="M1.053 13A1.049 1.049 0 0 1 0 11.959V1.039A1.049 1.049 0 0 1 1.053-.002h4.309v2.6a1.473 1.473 0 0 0 1.48 1.463H10v7.9a1.049 1.049 0 0 1-1.052 1.041zm5.7-9.789a.617.617 0 0 1-.616-.616V.242a.239.239 0 0 1 .24-.24 1.042 1.042 0 0 1 .669.24l2.475 2.064a.961.961 0 0 1 .347.743.161.161 0 0 1-.162.162z" />
        </svg>
    )
};

export const FilterCircleIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path d="M15.249 0H.751a.751.751 0 0 0-.53 1.28L6 7.061V13.5a.75.75 0 0 0 .32.614l2.5 1.749a.75.75 0 0 0 1.18-.614V7.061l5.78-5.78A.751.751 0 0 0 15.249 0z" />
        </svg>
    )
};


export const AlertIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="3" height="12" viewBox="0 0 3 12">
            <path d="M1.188-3.548H-.9l-.437-7.874h2.962zM-1.375-.792a1.305 1.305 0 0 1 .386-1.023 1.628 1.628 0 0 1 1.123-.347 1.579 1.579 0 0 1 1.1.355 1.3 1.3 0 0 1 .39 1.015 1.3 1.3 0 0 1-.394 1 1.549 1.549 0 0 1-1.1.367A1.6 1.6 0 0 1-.981.219a1.3 1.3 0 0 1-.394-1.011z" transform="translate(1.375 11.422)" fill="#0b5d91" />
        </svg>

    )
};

export const AlertCircleIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
            <g data-name="Alert circle">
                <g data-name="Rectangle 51" fill="#fff" stroke="#d33">
                    <rect width="21" height="21" rx="10.5" stroke="none" />
                    <rect x=".5" y=".5" width="20" height="20" rx="10" fill="none" />
                </g>
                <path data-name="Path 121" d="M1.188-3.548H-.9l-.437-7.874h2.962zM-1.375-.792a1.305 1.305 0 0 1 .386-1.023 1.628 1.628 0 0 1 1.123-.347 1.579 1.579 0 0 1 1.1.355 1.3 1.3 0 0 1 .39 1.015 1.3 1.3 0 0 1-.394 1 1.549 1.549 0 0 1-1.1.367A1.6 1.6 0 0 1-.981.219a1.3 1.3 0 0 1-.394-1.011z" transform="translate(10.375 16.422)" fill="#d33" />
            </g>
        </svg>
    )
};

export const InfoCircleIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
            <g data-name="Alert circle">
                <g data-name="Rectangle 51" fill="#fff" stroke="#0b5d91">
                    <rect width="21" height="21" rx="10.5" stroke="none" />
                    <rect x=".5" y=".5" width="20" height="20" rx="10" fill="none" />
                </g>
                <path data-name="Path 121" d="M1.188-3.548H-.9l-.437-7.874h2.962zM-1.375-.792a1.305 1.305 0 0 1 .386-1.023 1.628 1.628 0 0 1 1.123-.347 1.579 1.579 0 0 1 1.1.355 1.3 1.3 0 0 1 .39 1.015 1.3 1.3 0 0 1-.394 1 1.549 1.549 0 0 1-1.1.367A1.6 1.6 0 0 1-.981.219a1.3 1.3 0 0 1-.394-1.011z" transform="translate(10.375 16.422)" fill="#0b5d91" />
            </g>
        </svg>
    )
};
export const CloseIcon = ({ handleOnClick }: ClickIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" onClick={() => handleOnClick()} style={{ cursor: "pointer" }}>
            <path d="M12.6 0 7 5.6 1.4 0 0 1.4 5.6 7 0 12.6 1.4 14 7 8.4l5.6 5.6 1.4-1.4L8.4 7 14 1.4z" />
        </svg>
    )
};

export const EditIcon = ({ handleOnClick }: ClickIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" onClick={() => handleOnClick()} style={{ cursor: "pointer" }}>
            <path d="M0 7.917v2.087h2.083L8.227 3.86 6.144 1.774zm9.838-5.671a.553.553 0 0 0 0-.783l-1.3-1.3a.553.553 0 0 0-.783 0L6.738 1.179l2.083 2.083 1.017-1.017z" />
        </svg>
    )
};

export const EditCircleIcon = ({ handleOnClick }: ClickIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" onClick={() => handleOnClick()} style={{ cursor: "pointer" }}>
            <g data-name="Edit circle" transform="translate(-436 -523)">
                <rect width="21" height="21" rx="10.5" transform="translate(436 523)" fill="#0b5d91" />
                <path d="M4.5 12.413V14.5h2.083l6.144-6.144-2.083-2.086zm9.838-5.671a.553.553 0 0 0 0-.783l-1.3-1.3a.553.553 0 0 0-.783 0l-1.017 1.016 2.083 2.083 1.017-1.017z" transform="translate(437 524.004)" fill="#fff" />
            </g>
        </svg>
    )
};

export const CalendarIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="10" viewBox="0 0 9 10">
            <path d="M0 9.063A.951.951 0 0 0 .964 10h7.072A.951.951 0 0 0 9 9.063V3.75H0zm1.286-3.75A.318.318 0 0 1 1.607 5h1.929a.318.318 0 0 1 .321.313v1.875a.318.318 0 0 1-.321.312H1.607a.318.318 0 0 1-.321-.312zm6.75-4.063h-.965V.313A.318.318 0 0 0 6.75 0h-.643a.318.318 0 0 0-.321.313v.937H3.214V.313A.318.318 0 0 0 2.893 0H2.25a.318.318 0 0 0-.321.313v.937H.964A.951.951 0 0 0 0 2.188v.938h9v-.938a.951.951 0 0 0-.964-.938z" />
        </svg>
    )
};

export const ClipIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22">
            <defs>
                <clipPath id="rxrkkome1a">
                    <path data-name="Rectangle 676" d="M0 0h21v22H0z" />
                </clipPath>
            </defs>
            <g data-name="Group 548" clipPath="url(#rxrkkome1a)">
                <path data-name="Path 120" d="M6.865 22a6.822 6.822 0 0 1-4.9-11.612l9.025-8.965a4.947 4.947 0 0 1 6.942 0 4.875 4.875 0 0 1 .035 6.884L8.94 17.264a2.977 2.977 0 0 1-4.175 0 2.939 2.939 0 0 1-.021-4.15l8.323-8.254a1 1 0 0 1 1.408 1.421l-8.3 8.235a.937.937 0 0 0 0 1.327.971.971 0 0 0 1.36 0L16.53 6.92a2.878 2.878 0 0 0 0-4.075 2.942 2.942 0 0 0-4.126 0l-8.985 8.922a4.8 4.8 0 0 0 0 6.823 4.913 4.913 0 0 0 6.894 0l8.982-8.922a1 1 0 0 1 1.409 1.42l-8.983 8.923A6.881 6.881 0 0 1 6.865 22" />
            </g>
        </svg>
    )
};
