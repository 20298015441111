export enum Filters {
    All = "All",
    Archive = "Archived",
    New = "New",
    InProgress = "In progress",
    Active = "Active"
}

export enum OrderStatus {
    New = "new",
    InProgress = "in_progress",
    Archived = "archived"
}