import React from 'react';
import styled from 'styled-components';
import Loader from '../components/Loader';
import { isMobile } from 'react-device-detect';
import DirectusGateway from '../gateways/DirectusGateway';
import { IOrder } from '../models/interfaces/IOrder';
import Top from '../components/Top';
import { Filters, OrderStatus } from '../utils/Enums';
import Logger from '../utils/Logger';
import { Column } from '../utils/StyledComponents';
import Order from './Order';
import OrderData from './OrderData';
import { showAll } from '../components/GroupStoreDropDown';

const LoaderWrapper = styled.div`
  width:100vw;
  height:100vh;
`

const ListWrapper = styled.div`
  padding:  ${isMobile ? "0px 20px" : "20px 40px"};
`

const ListHeader = styled.div`
  padding:0 16px;
`

const CategoryTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px;
`

const Warning = styled.div`
  position: fixed;
  top: 70px;
  left:0px;

  display: flex;
  height: calc(100vh - 70px);
  width: 100vw;
  font-size: 16px;
  margin-right: 5px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(255,255,255,0.9);
`

export interface GroupFilter {
  group: string;
  store: string | null;
}

interface OrderListProps {
}

interface OrderListState {
  data: Array<IOrder>,
  isLoadingData: boolean,
  stateFilter: string,
  groupFilter: GroupFilter,
  showNewOrder: boolean
}

class OrderList extends React.Component<OrderListProps, OrderListState> {

  state: OrderListState = {
    data: [],
    isLoadingData: true,
    stateFilter: Filters.Active,
    groupFilter:  {group:showAll,store:null},
    showNewOrder: false
  };

  async componentDidMount() {
    const orderData = await DirectusGateway.getOrders();
    Logger.log(JSON.stringify(DirectusGateway.groupStores));
    // Logger.log(JSON.stringify(orderData));

    this.setState({
      data: orderData,
      isLoadingData: false
    });
  }

  async dataUpdated() {
    const orderData = await DirectusGateway.getOrders();

    this.setState({
      data: orderData
    });
  }

  async stateFilterUpdated(filter: string) {
    this.setState({
      stateFilter: filter
    });
  }
  async groupFilterUpdated(filter:GroupFilter) {
    this.setState({
      groupFilter: filter
    });
  }

  newOrder() {
    this.setState({
      showNewOrder: true
    });
  }

  closeNewOrder() {
    this.setState({
      showNewOrder: false
    });
  }


  closeNewOrderAndUpdateData() {
    Logger.log("closeNewOrderAndUpdateData");
    this.dataUpdated();
    // this.setState({
    //   showNewOrder: false
    // });
  }

  async saveNewOrder() {
    this.setState({
      showNewOrder: false
    });
  }

  filterOnRename(item: IOrder) {
    switch (this.state.stateFilter) {
      case Filters.All:
        return true;
      case Filters.Active:
        return item.status === OrderStatus.InProgress || item.status === OrderStatus.New;
      case Filters.InProgress:
        return item.status === OrderStatus.InProgress;
      case Filters.Archive:
        return item.status === OrderStatus.Archived;
      case Filters.New:
        return item.status === OrderStatus.New;
    }
  }

  filterOnGroup(item: IOrder) {
    if (this.state.groupFilter.group === showAll ) {
      return true;
    }
    if(this.state.groupFilter.store){
      return this.state.groupFilter.store === item.store_group_name;
    }else{
      return this.state.groupFilter.group === item.customer_id.name;
    }
  }

  render() {
    const ordersToShow = this.state.data.filter(this.filterOnRename.bind(this)).filter(this.filterOnGroup.bind(this));
    Logger.log(ordersToShow);
    return (
      <>
        {this.state.isLoadingData ?
          <LoaderWrapper>
            <Loader text='HENTER DATA' />
          </LoaderWrapper>
          :
          <>
            <Top onStateFilterChanged={(f: string) => this.stateFilterUpdated(f)} onGroupFilterChanged={(f: GroupFilter) => this.groupFilterUpdated(f)} onNewOrder={() => this.newOrder()} />
            <ListWrapper>
              {!isMobile &&
                <ListHeader>
                  <Column>
                    <CategoryTitle>Date of order</CategoryTitle>
                  </Column>
                  <Column>
                    <CategoryTitle>AP installation</CategoryTitle>
                  </Column>
                  <Column>
                    <CategoryTitle>ESL delivery</CategoryTitle>
                  </Column>
                  <Column>
                    <CategoryTitle>IO number</CategoryTitle>
                  </Column>
                  <Column>
                    <CategoryTitle>INC number</CategoryTitle>
                  </Column>
                  <Column>
                    <CategoryTitle>MIN number</CategoryTitle>
                  </Column>
                  <Column>
                    <CategoryTitle>Store ID</CategoryTitle>
                  </Column>
                  <Column>
                    <CategoryTitle>Store name</CategoryTitle>
                  </Column>
                  <Column>
                    <CategoryTitle>Ordertype</CategoryTitle>
                  </Column>
                  <Column style={{ width: '7%'}}>
                    <CategoryTitle>Progress</CategoryTitle>
                  </Column>
                </ListHeader>
              }
              <>
                {this.state.data &&
                  <>
                    {ordersToShow.map((d, i) => (
                      <Order key={d.id} orderData={d} dataUpdated={() => this.dataUpdated()} filter={this.state.stateFilter} />
                    ))}
                  </>
                }
              </>
            </ListWrapper>
          </>
        }

        {this.state.showNewOrder &&
          <OrderData dataUpdated={this.closeNewOrderAndUpdateData.bind(this)} orderId={-1} hideModal={this.closeNewOrder.bind(this)} />
        }

        {DirectusGateway.customerIds.length === 0 &&
          <Warning>
            <h3>You have no customers assigned</h3>
            <p>Please contact Eastwest instore.</p>
          </Warning>
        }

      </>

    )
  }
};

export default OrderList;