import React from 'react';
import { Redirect } from 'react-router-dom';

const RequireAuth = ({ children, auth }: any) => {
  return auth 
    ? children 
    : <Redirect 
        to="login"
			/>;
}

export const RequireServiceProviderAuth = ({ children, isUserServiceProvider }: any) => {
  return isUserServiceProvider
    ? children 
    : <Redirect 
        to="new-order"
      />;
}

export const RequireCustomerAuth = ({ children, isUserCustomer }: any) => {
  return isUserCustomer
    ? children 
    : <Redirect 
        to=""
      />;
}

export default RequireAuth;