// ========== ORDER COLLECTION ========== //

// ===== Order Dropdowns ===== //

export enum OrderType {
  LABEL_ORDER="label_order",
  FULL_INSTALLATION="full_installation",
  FULL_INSTALLATION_EXTRA_ESL="full_installation_extra_esl",
  SPECIAL_INSTALLATION="special_installation",
}

export enum ExistingInstallation {
  DELFI="delfi",
  PRICER="pricer",
}

export enum ESLServer {
  ONE_PIECE_STORE_INSTALLS="1_piece_store_installs_image",
  ONE_PIECE_PRE_INSTALLED="1_piece_with_pre_installed_image",
}

export enum Switch {
  USE_EXISTING="use_existing",
  NEW_SWITCH="new_switch",
}

export enum PoE {
  IS_INSTALLED="is_installed",
  TO_BE_INSATLLED="to_be_installed",
  NOT_TO_BE_INSTALLED="not_to_be_installed",
}

export enum Status {
  NEW="new",
  IN_PROGRESS="in_progress",
  ARCHIVED="archived",
}

// ========== PRODUCT COLLECTION ========== //

export interface Product {
  id: number;
  name: string;
  short_description?: string;
  long_description?: string;
}