// import { Store, CombinedState, AnyAction } from "redux";
// import { AdminProps } from "../store/adminReducer";
// import { StateProps } from "../store/stateReducer";
// import * as actions from '../store/stateActions';
import { ILoggerPlugin } from "./Logger";


// export class ScreenLogger implements ILoggerPlugin {
// reduxStore?: Store<CombinedState<{ adminReducer: AdminProps; stateReducer: StateProps; }>, AnyAction>;
export class WindowLogger implements ILoggerPlugin {

    // constructor(store: Store<CombinedState<{ adminReducer: AdminProps; stateReducer: StateProps; }>, AnyAction>) {
    //     this.reduxStore = store;
    // }

    execute(messages: Array<any>, type: string) {
        // @ts-ignore
        window.log += messages.toString() + " - ";
    }
}