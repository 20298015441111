import React, { ComponentType } from 'react';
import styled from 'styled-components';
import { FilledButton } from './components/Buttons';
import DirectusGateway from './gateways/DirectusGateway';
import Logger from './utils/Logger';

import { withRouter, RouteComponentProps} from 'react-router-dom'

export interface LoginProps extends RouteComponentProps<any> {
    updateLogInState: (isLoggedIn: boolean, isCustomer: boolean) => void;
}

interface LoginState {
    email: string;
    password: string;
    error: string;
    show: boolean;
    remember: boolean;
    showForgotPassword: boolean;
    showForgotPasswordReciep: boolean;
}

const LoginWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    padding: 40px;
`

const LoginForm = styled.div`
    display: flex;
    flex-direction: column;
    width: 300px;
    padding:32px;
    background-color: #FFF;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.11);
`

const Label = styled.div`
`

const ErrorMessage = styled.div`
    padding: 3px 6px;
    color: #FFF;
    font-size: 12px;
    background-color: #d33;
    text-align: center;
    margin-bottom: 8px;
`

const TextInput = styled.input`    
    box-sizing: border-box;
    border: 1px solid #999;
    outline: none;
    width: 100%;
    padding:8px;
    &:focus{        
        outline: none;
    }
`

const InputBlock = styled.div`
    margin-bottom: 8px;
`

const CheckboxBlock = styled.div`
    text-align:right;
    font-size: 13px;
    margin-bottom: 8px;
`

const SubmitBlock = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
    text-align: right;
    &>div{
        width:auto;
    }
`

const ForgotPassword = styled.div`
    margin-bottom: 8px;
    text-align:right;
    font-size: 13px;
    cursor: pointer;
`


class Login extends React.Component<LoginProps, LoginState> {
    state: LoginState = {
        email: (window.location.href.indexOf("localhost") === -1) ? "" : "moe@cphcloud.com",
        password: (window.location.href.indexOf("localhost") === -1) ? "" : "Cloud4Life",
        error: "",
        show: false,
        remember: false,
        showForgotPassword: false,
        showForgotPasswordReciep: false
    };

    async componentDidMount() {
        const l = DirectusGateway.getFromStorage();
        if (l) {
            const success = await DirectusGateway.login(l.email, l.password);
            if (success) {
                this.props.updateLogInState(true, DirectusGateway.isFlowUser);
                // @ts-ignore 
                this.props.history.push("/");
            }else{
                this.setState({ show: true });
            }
        } else {
            this.setState({ show: true });
        }
    }

    updatePassword(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ password: event.target.value, error: '' });

    }
    updateEmail(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ email: event.target.value, error: '' });
    }

    updateRememberMe() {
        this.setState({ remember: !this.state.remember });
    }

    onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.login();
        }
    }

    async login() {
        const success = await DirectusGateway.login(this.state.email, this.state.password);
        Logger.log(success);
        if (success) {
            DirectusGateway.setInStorage({ email: this.state.email, password: this.state.password, remember: this.state.remember });
            this.props.updateLogInState(true, DirectusGateway.isFlowUser);
            // @ts-ignore 
            this.props.history.push("/");
        }
        else {
            this.setState({
                error: "Couldn't log in. Please check your data."
            });
        }
    }

    async forgotPassword() {
        const result = await DirectusGateway.requestPasswordReset(this.state.email);

        if (result?.message === "NoData") {
            this.setState({ showForgotPasswordReciep: true, error: '' });
        }
        else {
            this.setState({
                error: "Couldn't reset your password.",
                showForgotPasswordReciep: false
            });
        }
    }


    render() {
        return (
            <>
                {this.state.show &&
                    <LoginWrapper>
                        <LoginForm>
                            {!this.state.showForgotPasswordReciep &&
                                <InputBlock>
                                    <Label>Email</Label>
                                    <TextInput type="text" value={this.state.email} onChange={this.updateEmail.bind(this)} onKeyDown={this.onKeyDown} />
                                </InputBlock>
                            }

                            {!this.state.showForgotPassword &&
                                <>
                                    <InputBlock>
                                        <Label>Password</Label>
                                        <TextInput type="password" value={this.state.password} onChange={this.updatePassword.bind(this)} onKeyDown={this.onKeyDown} />
                                    </InputBlock>
                                    <CheckboxBlock>
                                        <input type="checkbox" onChange={this.updateRememberMe.bind(this)} /> Remember me
                                    </CheckboxBlock>
                                </>
                            }
                            {this.state.error &&
                                <ErrorMessage>{this.state.error}</ErrorMessage>
                            }
                            {this.state.showForgotPassword ?
                                <>
                                    {this.state.showForgotPasswordReciep ?
                                        <div>
                                            An email has been sent.
                                        </div>
                                        :
                                        <>
                                            <SubmitBlock>
                                                <FilledButton onClick={this.forgotPassword.bind(this)}>Reset password</FilledButton>
                                            </SubmitBlock>
                                        </>
                                    }

                                    <ForgotPassword onClick={() => this.setState({ showForgotPassword: false, error: "", showForgotPasswordReciep:false })}>
                                        Back to login
                                    </ForgotPassword>
                                </>
                                :
                                <>
                                    <SubmitBlock>
                                        <FilledButton onClick={this.login.bind(this)}>Log in</FilledButton>
                                    </SubmitBlock>
                                    <ForgotPassword onClick={() => this.setState({ showForgotPassword: true, error: "", showForgotPasswordReciep:false })}>
                                        Forgot password
                                    </ForgotPassword>
                                </>
                            }
                        </LoginForm>
                    </LoginWrapper>
                }
            </>
        )
    };
}

export default withRouter<LoginProps, ComponentType<any>>(Login);