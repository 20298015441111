import React from "react";
import ReactDOM from "react-dom";
import './shared/index.css';
import App from './shared/App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);