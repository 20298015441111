import { ICustomers } from "../models/interfaces/ICustomers";
import { IFile } from "../models/interfaces/IFile";
import { IOrder, IPartialOrder } from "../models/interfaces/IOrder";
import { IPartialOrderItem } from "../models/interfaces/IOrderItem";
import { IOrderProcessItem, IPartialOrderProcessItem } from "../models/interfaces/IOrderProcessItem";
import { IStoreGroup } from "../models/interfaces/IStoreGroup";
import { IUser } from "../models/interfaces/IUser";
import { OrderStatus } from "../utils/Enums";
import Logger from "../utils/Logger";
import { makeSubFields, rewrite } from "../utils/Query";
import { prettifyOrderType } from "../utils/Strings";

enum Permission {
    Orders,
    FlowAndOrders,
    AssignedCustomers,
    AllCustomers,
    OwnCustomer
}

class DirectusGateway {

    private authToken = "";
    private refreshToken = "";
    private _baseUrl = process.env.REACT_APP_DIRECTUS_ENDPOINT!;
    private _customerIds: Array<number> = [];
    private _customers: Array<ICustomers> = [];
    private orderData: Array<IOrder> = [];
    private _userData: IUser = {};

    private roles = [{
        title: "Order creator",
        id: "6ef4f7c7-527b-443e-91f6-27ad11cd26c8",
        permissions: [Permission.Orders, Permission.OwnCustomer]
    },
    {
        title: "Flow manager",
        id: "9ba077e6-1982-4018-a0d7-471b7f529ad0",
        permissions: [Permission.FlowAndOrders, Permission.AssignedCustomers]
    },
    {
        title: "Administrator",
        id: "7331392c-adf5-4344-908c-f88f756ae33f",
        permissions: [Permission.FlowAndOrders, Permission.AllCustomers]
    },
    {
        title: "Eastwest admin",
        id: "0403f002-495c-45aa-bef5-29854eb11ea8",
        permissions: [Permission.FlowAndOrders, Permission.AllCustomers]
    }];

    // #region getters
    get directusUrl() {
        return this._baseUrl;
    }

    get authString() {
        return "Bearer " + this.authToken;
    }

    get customerIds(): Array<number> {
        return this._customerIds;
    }

    get customers(): Array<ICustomers> {
        return this._customers;
    }

    get userName() {
        return this._userData.first_name + " " + this._userData.last_name;
    }

    get userData() {
        return this._userData;
    }

    get isLoggedIn() {
        return !!this.authToken;
    }

    get isFlowUser(): boolean {
        return (this.roles.some((role) => this._userData.role === role.id && (role.permissions.some(p => p === Permission.FlowAndOrders))));
    }

    get isAdmin(): boolean {
        return (this.roles.some((role) => this._userData.role === role.id && (role.permissions.some(p => p === Permission.AllCustomers))));
    }

    get isInSharePoint() {
        return window.location.href.indexOf("sharepoint") !== -1;
    }


    get groupStores(): Array<IStoreGroup> {
        //Lav bruttoliste med kæder og subbrands
        let allGroupAndStores = this.orderData.map((order) => {
            return { groupName: order.customer_id.name, storeName: order.store_group_name };
        });
        let storeGroups = new Array<IStoreGroup>();

        //Find alle kædenavne
        allGroupAndStores.forEach((group) => {
            if (!storeGroups.some((g) => g.group === group.groupName) && group.groupName) {
                storeGroups.push({ group: group.groupName, stores: [] });
            }
        });
        storeGroups.sort((a, b) => a.group > b.group ? 1 : -1);

        //Find alle subbrands for den enkelte kæde
        storeGroups.forEach((storeGroup) => {
            let storesForGroup = allGroupAndStores.filter((g) => storeGroup.group === g.groupName && g.storeName);

            storesForGroup.forEach((group) => {
                if (!storeGroup.stores.some((g) => g === group.storeName)) {
                    storeGroup.stores.push(group.storeName);
                }
            });

            storeGroup.stores.sort((a, b) => a > b ? 1 : -1);
        });

        return storeGroups;
    }

    //#endregion


    // #region local and session storage

    reset() {
        window.localStorage.removeItem("doYouRemember");
        window.sessionStorage.removeItem("doYouRemember");
        window.location.reload();
    }

    setInStorage(state: { email: any; password: string; remember: boolean }) {
        if (state.remember) {
            window.localStorage.setItem("doYouRemember", JSON.stringify({ email: state.email, password: state.password }));
        }
        window.sessionStorage.setItem("doYouRemember", JSON.stringify({ email: state.email, password: state.password }));
    }

    getFromStorage() {
        let login = window.localStorage.getItem("doYouRemember");
        if (!login) {
            login = window.sessionStorage.getItem("doYouRemember");
        }
        if (login) {
            return JSON.parse(login);
        }
        return null;
    }



    //#endregion


    // #region local lookups

    findOrder(orderId: number) {
        return this.orderData.find((item) => item.id === orderId);
    }

    findOrderProcess(orderId: number, orderProcessId: number) {
        const order = this.findOrder(orderId);
        return order?.processes.find((orderProcess) => orderProcess.id === orderProcessId);
    }
    //#endregion


    // #region Directus

    async getOrders() {
        if (!this._customerIds.length) {
            Logger.warn("Cant get orders. No customers");
            return Promise.resolve([]);
        }
        Logger.log(this._customerIds.join(','));
        const custFields = makeSubFields("customer_id", "date_created,name,service_partner_id,process_id");
        const itemFields = makeSubFields("items", "id,product_id,order_id,quantity,product_name,product_short_description");

        const fields = `${itemFields},${custFields},id,date_created,store_internal_id,store_name,store_address,store_zip,store_city,store_country,store_responsible_name,store_responsible_phone,store_group_name,ordered_by,customer_case_number,service_partner_case_number,order_type,delivery_date,store_size,ap_count,ap_installations,ap_due_date,existing_installation,esl_server,esl_server_due_date,esl_delivery_date,switch,switch_due_date,poe,ip_range,newly_built,order_note,floor_plan_file,process_id,status,internal_order_number,ew_case_number,internal_notification_number,ordered_by_email`;//,process_items

        const filters = "?filter[customer_id][_in]=" + this._customerIds.join(',') + "&" + rewrite(fields) + "&sort=-date_created";

        this.orderData = await this.getLoggedInData("items/orders", filters);

        for (let data of this.orderData) {
            data.processes = await this.getProcessItems(data.id);
        };

        return Promise.resolve(this.orderData as Array<IOrder>);
    }

    async getOrderData(orderId: string) {
        this.orderData = await this.getLoggedInData("items/orders/" + orderId, "");

        for (let data of this.orderData) {
            data.processes = await this.getProcessItems(data.id);
        };

        return Promise.resolve(this.orderData as Array<IOrder>);
    }

    async getProcessItems(orderId: number) {
        const itemFields = makeSubFields("process_item_id", "id,name,description,process_group,ongoing,position,process_id.name,service_partner_id.name,service_partner_id.color,notification_email");

        const fields = `${itemFields},id,due_date,order_id,note,status`;

        return this.getLoggedInData("items/order_process_items", "?filter[order_id]=" + orderId + "&" + rewrite(fields));
    }

    async updateProcessState(processItemid: number, orderId: number, newState: string) {
        const process = this.findOrderProcess(orderId, processItemid);
        if (process) {
            process.status = newState;
        }
        const body = JSON.stringify({
            status: newState
        });

        return this.updateProcess(processItemid, orderId, body);
    }

    async updateProcessNote(processItemid: number, orderId: number, note: string, deadline: Date | null) {
        const process = this.findOrderProcess(orderId, processItemid);
        if (process) {
            process.due_date = (deadline) ? deadline.toString() : null;
            process.note = note;
        }

        const body = JSON.stringify({
            note: note,
            due_date: deadline
        });

        return this.updateProcess(processItemid, orderId, body);
    }

    private async updateProcess(processItemid: number, orderId: number, body: string) {
        const url = "items/order_process_items/" + processItemid;
        const data = this.getAuthData('PATCH', body);
        await this.updateOrderStatusToInProgress(orderId);
        return this.sendAndRecieveData(url, data);
    }

    async updateOrderStatus(orderId: number, status: string) {
        const url = "items/orders/" + orderId;
        const data = this.getAuthData('PATCH', JSON.stringify({ status }));
        return this.sendAndRecieveData(url, data);
    }

    async updateOrderStatusToInProgress(orderId: number) {
        const order = this.findOrder(orderId);
        if (order && order.status !== OrderStatus.New) {
            return;
        } else if (order) {
            order.status = OrderStatus.InProgress;
        }
        const url = "items/orders/" + orderId;
        const data = this.getAuthData('PATCH', JSON.stringify({ status: OrderStatus.InProgress }));
        return this.sendAndRecieveData(url, data);
    }

    async sendCompletedMail(processItem: IOrderProcessItem, orderId: number) {
        Logger.log("sendCompletedMail");
        const order = this.findOrder(orderId);
        if (!order) {
            return;
        }

        const url = process.env.REACT_APP_EMAIL_ENDPOINT!;
        let subject = `Process: '${processItem.process_item_id.name}' completed for ${order.customer_id.name} - ${order.store_name}`;

        let mailBody = `
            <h3>${subject}</h3>
            <p><b>Customer</b>: ${order.customer_id.name}<br/>
            <b>Store</b>: ${order.store_name}<br/>
            <b>Order type</b>: ${prettifyOrderType(order.order_type)}</p>
            `;

        const completed = order.processes.filter((p) => p.status.toLowerCase() === "done");
        if (completed.length > 0) {
            const items = completed.map((item) => `<li>${item.process_item_id.name} (${item.id})</li>`);
            mailBody += `<p><b>Completed process items</b>:<ul>${items.join("")}</ul></p>`;
        }

        if (order.items?.length > 0) {
            const items = order.items.map((item) => item.quantity + " of " + item.product_name);
            mailBody += `<p><b>Products</b>: ${items.join(", ")}</p>`;
        }

        mailBody += (processItem.note) ? `<b>Note</b>: ${processItem.note}` : "";
        mailBody += (processItem.due_date) ? `<p><b>Deadline</b>: ${processItem.due_date}</p>` : "";

        mailBody += `<p>See more: <a href='${window.location.href}'>See more  at ${window.location.href}</a></p>`;
        
        let recievers = processItem.process_item_id.notification_email.split(',');

        let to = recievers.map((mail)=>{
            if(mail.indexOf('[') === 0 && mail.indexOf(']') === (mail.length-1)){
                const key = mail.replaceAll('[','').replaceAll(']',''); 
                //@ts-ignore
                return order[key.toLowerCase()];
            }
            return mail;
        });

        const body = {
            to: to.filter(String).join(','),
            subject: subject,
            body: mailBody,
            type: "html"
        };

        Logger.log(body);

        return fetch(this.addAntiCache(url), this.getAuthData('POST', JSON.stringify(body)))
            // return fetch(this.addAntiCache(url), this.getAuthData('GET'))//For simple test
            .then(response => response.json())
            .then(recievedData => {
                Logger.log(recievedData);
                return recievedData;
            }).catch(error => {
                if (error) {
                    Logger.error(error);
                }
                return null;
            });
    }

    

    async login(email: string, password: string) {
        const authData = await this.authenticate(email, password);
        Logger.log(authData);

        if (authData) {
            this.authToken = authData.access_token;
            this.refreshToken = authData.refresh_token;
            this.refreshTokenAfter(authData.expires);
            const userData = await this.getUserData();
            if (userData?.role) {
                this._userData = userData as IUser;
                if (this.isAdmin) {//Virker ikke
                    this._customers = await this.getCustomers();
                    Logger.debug("isAdmin",this._customers);
                    if (this._customers) {
                        this._customerIds = this._customers.map((i: ICustomers) => i.id);
                        return true;
                    }
                }                
                else if (this.userData.customer_id) {
                    this._customerIds = [this.userData.customer_id];
                    const userCustomers: ICustomers[] = await this.getCustomers();
                    this._customers = userCustomers.filter(customer => this.userData.customer_id === customer.id);
                    return true;
                } 
                else if (this._userData.service_partner_id) {
                    const servicePartnerIds = [this._userData.service_partner_id];
                    this._customers = await this.getCustomerFromServicepartner(servicePartnerIds.join(","));
                    Logger.debug("sp cust: ",this._customers);
                    if (this._customers) {
                        this._customerIds = this._customers.map((i: ICustomers) => i.id);
                        return true;
                    }
                }
            }
        }
        return false;
    }

    async requestPasswordReset(email: string) {
        const url = "auth/password/request";
        const data = {
            method: 'POST',
            headers: new Headers({
                'Content-type': 'application/json; charset=UTF-8',
                'Accept': 'application/json'
            }),
            body: JSON.stringify({ email, reset_url: window.location.origin + "/#/reset" })
        };

        return this.sendAndRecieveData(url, data);
    }

    //https://docs.directus.io/reference/authentication/#request-password-reset
    async resetPassword(password: string, token: string) {
        const url = "auth/password/reset";
        const data = {
            method: 'POST',
            headers: new Headers({
                'Content-type': 'application/json; charset=UTF-8',
                'Accept': 'application/json'
            }),
            body: JSON.stringify({ token, password })
        };

        return this.sendAndRecieveData(url, data);
    }

    private async authenticate(email: string, password: string) {
        const url = "auth/login";

        const data = {
            method: 'POST',
            headers: new Headers({
                'Content-type': 'application/json; charset=UTF-8',
                'Accept': 'application/json'
            }),
            body: JSON.stringify({ email, password })
        };

        return this.sendAndRecieveData(url, data);
    }

    private refreshTokenAfter(time: number) {
        setTimeout(this.refeshAccessToken.bind(this), time);
    }

    private async refeshAccessToken() {
        const url = "auth/refresh";

        const data = this.getAuthData('POST', JSON.stringify({ refresh_token: this.refreshToken }));

        const refreshData = await this.sendAndRecieveData(url, data);
        this.authToken = refreshData.access_token;
        this.refreshToken = refreshData.refresh_token;
        this.refreshTokenAfter(refreshData.expires)
    }

    async getUserData() {
        return this.getLoggedInData("users/me", "");
    }

    async getServicepartnerFromUser(userId: string) {
        // return this.getLoggedInData("items/service_partners", "?filter[roles]=" + roleId);

        return this.getLoggedInData("items/service_partners", "?filter[users][_in]=" + userId);
    }

    async getCustomerFromServicepartner(servicepartnerId: string) {
        return this.getLoggedInData("items/customers", "?filter[service_partner_id]=" + servicepartnerId);
    }

    async getCustomers() {
        return this.getLoggedInData("items/customers", "");
    }

    async getCustomerById(customerId: number) {
        return this.getLoggedInData("items/customers/" + customerId, "");
    }

    async getProducts() {
        return this.getLoggedInData("items/products", "");
    }

    async getStoresFromCustomer(customerId: number) {
        return this.getLoggedInData("items/stores", "?limit=9999&filter[customer_id][_eq]=" + customerId);
    }

    async getServicePartnerFromId(servicePartnerId: number) {
        return this.getLoggedInData("items/service_partners/" + servicePartnerId, "");
    }

    async getFileFromId(fileId: string) {
        return this.getLoggedInData("files/" + fileId, "");
    }

    async createOrder(order: IPartialOrder) {
        return this.postLoggedInData("items/orders", "", JSON.stringify(order));
    }

    async getProcessItemsFromProcess(processId: number) {
        return this.getLoggedInData("items/process_items", "?filter[process_id][_eq]=" + processId);
    }

    async createOrderProcessItem(orderProcessItem: IPartialOrderProcessItem) {
        return this.postLoggedInData("items/order_process_items", "", JSON.stringify(orderProcessItem));
    }

    async createOrderItem(orderItem: IPartialOrderItem) {
        return this.postLoggedInData("items/order_items", "", JSON.stringify(orderItem));
    }

    async updateOrder(orderId: number, order: IPartialOrder) {
        return this.patchLoggedInData("items/orders/" + orderId, "", JSON.stringify(order));
    }

    async updateOrderItem(orderItemId: number, orderItem: IPartialOrderItem) {
        return this.patchLoggedInData("items/order_items/" + orderItemId, "", JSON.stringify(orderItem));
    }

    downloadDocument(fileId: string) {
        window.location.href = this._baseUrl + "assets/" + fileId + "?download";
    }

    uploadFile(file: File, callback: (directusFile: IFile) => void) {
        const request = new XMLHttpRequest();
        const formData = new FormData();
        
        request.open("POST", this._baseUrl + "files", true);
        request.onreadystatechange = () => {
        if (request.readyState === 4 && request.status === 200) {
            const response = JSON.parse(request.responseText);
            callback(response.data);
        }
        }
        request.setRequestHeader("Authorization", this.authString);
        formData.append('file', file);
        request.send(formData);
    }

    private async getLoggedInData(route: string, filter: string) {
        const url = "" + route + filter;
        const data = this.getAuthData();
        return this.sendAndRecieveData(url, data);
    }

    private async postLoggedInData(route: string, filter: string, body: string) {
        const url = "" + route + filter;
        const data = this.getAuthData("POST", body);
        return this.sendAndRecieveData(url, data);
    }

    private async patchLoggedInData(route: string, filter: string, body: string) {
        const url = "" + route + filter;
        const data = this.getAuthData("PATCH", body);
        return this.sendAndRecieveData(url, data);
    }

    private async sendAndRecieveData(url: string, data: object) {
        Logger.log(url);
        Logger.log(data);
        return fetch(this.addAntiCache(this._baseUrl + url), data)
            .then(response => response.text())
            .then(recievedData => {
                if (!recievedData) {
                    return { message: "NoData" };
                }
                const jsonData = JSON.parse(recievedData);
                if (!this.hasErrors(jsonData)) {
                    Logger.log(jsonData);
                    return jsonData.data;
                }
                return null;
            }).catch(error => {
                if (error)
                    Logger.error(error);
                return null;
            });
    }

    private getAuthData(method: string = "GET", body: string = "") {
        let data: any = {
            method,
            headers: new Headers({
                'Content-type': 'application/json; charset=UTF-8',
                'Accept': 'application/json',
                'Authorization': this.authString
            })
        };

        if (body) {
            data.body = body;
        }

        return data;
    }


    private addAntiCache(url: string) {
        url += (url.indexOf('?') === -1) ? "?" : "&";
        return url + "rnd=" + Math.round(Math.random() * 100000);
    }

    private hasErrors(recievedData: any) {
        if (recievedData.errors?.length) {
            recievedData.errors.forEach((element: { message: string; }) => {
                Logger.error("error: " + element.message);
            });
            return true;
        } else {
            return false;
        }
    }

    //#endregion
}

export default new DirectusGateway();
