export const stateTitles = ["Not set", "Pending", "Ongoing", "Done"];
export const stateKeys = ["Not set", "pending", "ongoing", "done"];
export const stateColors = ["#d1d1d1", "#d83", "#0b5e91", "#008a00"];//"#d33"

export function getStateIndex(status:string){
    return stateKeys.findIndex((item, id) => item === status);
}

export function getStateTitle(status:string){
    const id = getStateIndex(status);
    return stateTitles[id];
}

export function getStateColor(status:string){
    const id = getStateIndex(status);
    return stateColors[id];
}