import React from 'react';
import styled from 'styled-components';
import { FilledButton } from './Buttons';
import { AlertCircleIcon } from '../utils/icons';
import { DialogWrapper } from '../utils/StyledComponents';

interface DialogProps {
  message: string;
  dialogClosed:Function;
  title: string;
}

const Dialog = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 580px;
  padding:32px;
  background-color: #FFF;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.11);
`

const Title = styled.div`
  margin-top:8px;
`

const Message = styled.div`
  margin-top:8px;
`

const ButtonRow = styled.div`
  margin-top:40px;
  display: flex;
`

class AlertDialog extends React.Component<DialogProps>  {

  hideDialog() {
    this.props.dialogClosed();
  }

  render() {
    return (
      <>
        <DialogWrapper onClick={() => this.hideDialog()} >
          <Dialog onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
            <AlertCircleIcon />
            <Title>{this.props.title}</Title>
            <Message>
            {this.props.message}
            </Message>
            <ButtonRow><FilledButton onClick={(e: React.MouseEvent<HTMLDivElement>) => this.props.dialogClosed()}>Close</FilledButton></ButtonRow>
          </Dialog>
        </DialogWrapper>
      </>
    );
  };
}

export default AlertDialog;