import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-date-picker';
import Dropzone from 'react-dropzone';
import { isMobile, isTablet } from 'react-device-detect';

import Seperator from './components/Seperator';
import StoreSearchbar from './components/StoreSearchbar';
import Inputfield from './components/Inputfield';
import Dropdown from './components/Dropdown';
import Label from './components/Label';
import Button from './components/Button';
import Textarea from './components/Textarea';

import theme from './theme';

import DirectusGateway from '../gateways/DirectusGateway';

import * as API_I from './interfaces/api_interfaces';
import * as I from './interfaces/interfaces';

import { IOrder } from '../models/interfaces/IOrder';
import { IOrderItem } from '../models/interfaces/IOrderItem';
import { IProducts } from '../models/interfaces/IProducts';
import { IFile } from '../models/interfaces/IFile';
import { IStore } from '../models/interfaces/IStore';
import { ICustomers } from '../models/interfaces/ICustomers';
import { IServicePartner } from '../models/interfaces/IServicePartner';

import T, { E } from './assets/translations';
import { UploadFile, CalendarIcon, UnDrawOrderDelivered, UnDrawUseBrowser } from './assets/icons';
import "./assets/customDatePicker.css";
import Loader from '../components/Loader';
import { addHours } from '../utils/Dates';
import NewDropdown from './components/NewDropdown';

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.lightBlue};
  padding: 40px 40px 0px 40px;
  box-sizing: border-box;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const GridRow = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  align-items: center;

  > * {
    justify-self: end;

    &:first-child {
      justify-self: start;
    }
  }
`;

const ButtonNavigationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  margin-top: 40px;
`;

const Dropfield = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  padding: 8px 16px;
  text-align: center;
  
  /* border: 1px dashed black */
  background: linear-gradient(to right, black 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(black 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(to right, black 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(black 50%, rgba(255, 255, 255, 0) 0%);
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  background-size: 10px 1.5px, 1.5px 10px;
`;

const EmptySpace = styled.div`
  min-height: 40px;
  width: 100%;
`;

const OrderComleteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface NewOrderProps {
  order?: IOrder;
  orderItems?: IOrderItem[];
  orderUpdatedSuccessfullyCallback?: () => void;
  cancelCallback?: () => void;
  modal?: boolean;
}

const NewOrder = ({ order, orderItems, orderUpdatedSuccessfullyCallback = () => null, cancelCallback = () => null, modal = false }: NewOrderProps) => {
  // Edit "Mode" basically translates to: If this component is loaded with data, we are editing an existing Order, else we are creating a new one
  const edit = order ? true : false;

  // Ref object for the scroll container
  const wrapperRef = useRef<HTMLDivElement>(null);

  // True when the create/edit order is called, and set back to false when it's done.
  const [loading, setLoading] = useState(false);

  const [storeSearch, setStoreSearch] = useState<string>("");

  // Used to keep track on which "stage" we are in the process of creating the new order
  const [stage, setStage] = useState(0);

  // Stores
  const [stores, setStores] = useState<IStore[]>([]);

  // Customer
  const [customers] = useState<ICustomers[]>(DirectusGateway.customers);
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomers | undefined>();
  const [servicePartner, setServicePartner] = useState<IServicePartner | undefined>();

  // Store Details
  const [storeKardex, setStoreKardex] = useState<string>(order?.store_internal_id || "");
  const [storeName, setStoreName] = useState<string>(order?.store_name || "");
  const [storeAddress, setStoreAddress] = useState<string>(order?.store_address || "");
  const [storeZipCode, setStoreZipCode] = useState<string>(order?.store_zip || "");
  const [storeCity, setStoreCity] = useState<string>(order?.store_city || "");
  const [storeCountry, setStoreCountry] = useState<string>(order?.store_country || "");
  const [storeResponsibleName, setStoreResponsibleName] = useState<string>(order?.store_responsible_name || "");
  const [storeResponsiblePhone, setStoreResponsiblePhone] = useState<string>(order?.store_responsible_phone || "");
  const [storeGroupName, setStoreGroupName] = useState<string | null>(order?.store_group_name || null);

  // Order Details
  const [orderedBy, setOrderedBy] = useState<string>(order?.ordered_by || DirectusGateway.userName);
  const [orderedByEmail, setOrderedByEmail] = useState<string>(order?.ordered_by_email?.toString() || "");
  const [customerCaseNumber, setCustomerCaseNumber] = useState<string>(order?.customer_case_number || "");
  const [internalOrderNumber, setInternalOrderNumber] = useState<string>(order?.internal_order_number || "");
  const [internalNotificationNumber, setInternalNotificationNumber] = useState<string>(order?.internal_notification_number || "");
  const [servicePartnerCaseNumber, setServicePartnerCaseNumber] = useState<string>(order?.service_partner_case_number || "");
  const [eastWestCaseNumber, setEastWestCaseNumber] = useState<string>(order?.ew_case_number || "");

  // Order Details
  const [orderType, setOrderType] = useState<API_I.OrderType | undefined>(order ? order.order_type as API_I.OrderType : undefined);
  const [deliveryDate, setDeliveryDate] = useState<string>(order?.delivery_date || "");
  const [storeSize, setStoreSize] = useState<string>(order?.store_size?.toString() || "");
  const [APCount, setAPCount] = useState<string>(order?.ap_count?.toString() || "");
  const [APDueDate, setAPDueDate] = useState<Date | null>(order?.ap_due_date ? new Date(order.ap_due_date) : null);
  const [existingInstallation, setExistingInstallation] = useState<API_I.ExistingInstallation | undefined>(order ? order.existing_installation as API_I.ExistingInstallation : undefined);
  const [ESLServer, setESLServer] = useState<API_I.ESLServer | undefined>(order ? order.esl_server as API_I.ESLServer : undefined);
  const [ESLServerDueDate, setESLServerDueDate] = useState<Date | null>(order?.esl_server_due_date ? new Date(order.esl_server_due_date) : null);
  const [ESLDeliveryDate, setESLDeliveryDate] = useState<Date | null>(order?.esl_delivery_date ? new Date(order.esl_delivery_date) : null);
  const [_switch, setSwitch] = useState<API_I.Switch | undefined>(order ? order.switch as API_I.Switch : undefined);
  const [switchDueDate, setSwitchDueDate] = useState<Date | null>(order?.switch_due_date ? new Date(order.switch_due_date) : null);
  const [PoE, setPoE] = useState<API_I.PoE | undefined>(order ? order.poe as API_I.PoE : undefined);
  const [IPRange, setIPRange] = useState<string>(order?.ip_range || "");
  const [newlyBuilt, setNewlyBuilt] = useState<boolean>(order?.newly_built || false);
  const [orderNote, setOrderNote] = useState<string>(order?.order_note || "");
  const [floorPlanFile, setFloorPlanFile] = useState<IFile | null>(null);
  const [processId, setProcessId] = useState<number | undefined>(order?.process_id || undefined);
  const [status, setStatus] = useState<API_I.Status | undefined>(order ? order.status as API_I.Status : API_I.Status.NEW);

  // Products
  const [products, setProducts] = useState<I.OrderItem[]>(orderItems ?
    orderItems.map(o => ({
      product_id: o.product_id, product_name: o.product_name, quantity: o.quantity, product_short_description: o.product_short_description
    })) : []
  );

  useEffect(() => {
    if (customers.length === 1) {
      setSelectedCustomer(customers[0]);
      setProcessFromCustomerId(customers[0].id);
      setStage(1);
    }
  }, [customers]);

  const setProcessFromCustomerId = async (customerId: number) => {
    const _customer: ICustomers = await DirectusGateway.getCustomerById(customerId);
    setProcessId(_customer.process_id);
  }

  // Component Mount useEffect - Will fetch necessary data from Directus to use the new/edit order interface
  useEffect(() => {
    const fetchProducts = async () => {
      const _products: IProducts[] = await DirectusGateway.getProducts();

      setProducts(_products.map((product: IProducts) => ({
        product_id: product.id,
        product_name: product.name,
        quantity: 0,
        product_short_description: product.short_description || ""
      })));
    }

    const fetchStores = async () => {
      if (!selectedCustomer) return;

      const _stores = await DirectusGateway.getStoresFromCustomer(selectedCustomer.id);
      setStores(_stores);
    }

    const fetchCustomerServicePartner = async (customer: ICustomers) => {
      const _servicePartner: IServicePartner = await DirectusGateway.getServicePartnerFromId(customer.service_partner_id);
      setServicePartner(_servicePartner);
    }

    const fetchOrderFloorPlan = async () => {
      if (!order || !order.floor_plan_file) return;

      const _floorPlanFile = await DirectusGateway.getFileFromId(order.floor_plan_file);
      setFloorPlanFile(_floorPlanFile);
    }

    // If this creating a new order
    if (!order) {
      fetchProducts();

      if (selectedCustomer) {
        fetchStores();
        fetchCustomerServicePartner(selectedCustomer);
        setProcessFromCustomerId(selectedCustomer.id);
      }
    }
    // If this is editing an existing order
    else {
      order.floor_plan_file && fetchOrderFloorPlan();
      setSelectedCustomer(order.customer_id);
      fetchCustomerServicePartner(order.customer_id);
    }
  }, [order, selectedCustomer]);

  const setStoreSizeAndUpdateAPCount = (value: string) => {
    setStoreSize(value);
    const size = parseInt(value);
    if (size && size > 0) {
      setAPCount(Math.ceil(size / 250).toString());
    } else {
      setAPCount("0");
    }
  }

  const mergeDataToText = (text: string) => {
    return text
      .replace("<customer>", selectedCustomer?.name ?? T.CUSTOMER)
      .replace("<service_partner>", servicePartner?.name ?? T.SERVICE_PARTNER);
  }

  const createOrder = async () => {
    if (!processId || !selectedCustomer) {
      alert("Something went wrong!");
      return;
    }

    setLoading(true);

    // POST create order
    const _order: IOrder = await DirectusGateway.createOrder({
      customer_id: selectedCustomer.id,
      ordered_by: orderedBy,
      ordered_by_email: orderedByEmail,
      customer_case_number: customerCaseNumber,
      service_partner_case_number: servicePartnerCaseNumber,
      order_type: orderType,
      store_size: parseInt(storeSize),
      ap_count: parseInt(APCount),
      ap_due_date: APDueDate || undefined,
      existing_installation: existingInstallation,
      esl_server: ESLServer,
      esl_server_due_date: ESLServerDueDate || undefined,
      esl_delivery_date: ESLDeliveryDate || undefined,
      switch: _switch,
      switch_due_date: switchDueDate || undefined,
      poe: PoE,
      ip_range: IPRange,
      newly_built: newlyBuilt,
      order_note: orderNote,
      floor_plan_file: floorPlanFile?.id,
      process_id: processId,
      status: status,
      store_internal_id: storeKardex,
      store_name: storeName,
      store_address: storeAddress,
      store_zip: storeZipCode,
      store_city: storeCity,
      store_country: storeCountry,
      store_group_name: storeGroupName || "",
      internal_order_number: internalOrderNumber,
      internal_notification_number: internalNotificationNumber,
      ew_case_number: eastWestCaseNumber,
      store_responsible_name: storeResponsibleName,
      store_responsible_phone: storeResponsiblePhone,
      delivery_date: deliveryDate,
    });

    // GET all process_items
    const _processItems = await DirectusGateway.getProcessItemsFromProcess(processId);

    // POST all process_items to order_process_items with order.id
    for (const processItem of _processItems) {
      await DirectusGateway.createOrderProcessItem({
        order_id: _order.id,
        process_item_id: processItem.id,
        status: "pending"
      });
    }

    // POST all products to order_items with order.id
    for (const orderItem of products) {
      if (orderItem.quantity === 0) continue;

      await DirectusGateway.createOrderItem({
        order_id: _order.id,
        product_id: orderItem.product_id,
        quantity: orderItem.quantity,
        product_name: orderItem.product_name,
        product_short_description: orderItem.product_short_description
      });
    }

    orderUpdatedSuccessfullyCallback();
    setLoading(false);
  }

  const saveOrder = async () => {
    if (!order) return;
    if (!orderItems) return;

    setLoading(true);

    // PATCH update order
    await DirectusGateway.updateOrder(order.id, {
      ordered_by: orderedBy,
      ordered_by_email: orderedByEmail,
      customer_case_number: customerCaseNumber,
      service_partner_case_number: servicePartnerCaseNumber,
      order_type: orderType,
      store_size: parseInt(storeSize),
      ap_count: parseInt(APCount),
      ap_due_date: APDueDate || undefined,
      existing_installation: existingInstallation,
      esl_server: ESLServer,
      esl_server_due_date: ESLServerDueDate || undefined,
      esl_delivery_date: ESLDeliveryDate || undefined,
      switch: _switch,
      switch_due_date: switchDueDate || undefined,
      poe: PoE,
      ip_range: IPRange,
      newly_built: newlyBuilt,
      order_note: orderNote,
      floor_plan_file: floorPlanFile?.id,
      status: status,
      store_internal_id: storeKardex,
      store_name: storeName,
      store_address: storeAddress,
      store_zip: storeZipCode,
      store_city: storeCity,
      store_country: storeCountry,
      internal_order_number: internalOrderNumber,
      internal_notification_number: internalNotificationNumber,
      ew_case_number: eastWestCaseNumber,
      store_responsible_name: storeResponsibleName,
      store_responsible_phone: storeResponsiblePhone,
      delivery_date: deliveryDate,
    });

    // PATCH update all orderItems
    for (const orderItem of products) {
      const orderItemId = orderItems.find(oi => oi.product_id === orderItem.product_id)?.id;
      if (!orderItemId) continue;

      await DirectusGateway.updateOrderItem(orderItemId, {
        product_id: orderItem.product_id,
        quantity: orderItem.quantity,
        product_name: orderItem.product_name,
        product_short_description: orderItem.product_short_description
      })
    }

    setLoading(false);
    orderUpdatedSuccessfullyCallback();
  }

  const resetState = () => {
    setStage(1);
    setStoreSearch("");
    setStoreKardex("");
    setStoreName("");
    setStoreAddress("");
    setStoreZipCode("");
    setStoreCity("");
    setStoreCountry("");
    setStoreResponsibleName("");
    setStoreResponsiblePhone("");
    setOrderedBy(DirectusGateway.userName);
    setOrderedByEmail("");
    setCustomerCaseNumber("");
    setInternalOrderNumber("");
    setServicePartnerCaseNumber("");
    setEastWestCaseNumber("");
    setOrderType(undefined);
    setDeliveryDate("");
    setStoreSize("");
    setAPCount("");
    setAPDueDate(null);
    setExistingInstallation(undefined);
    setESLServer(undefined);
    setESLServerDueDate(null);
    setESLDeliveryDate(null);
    setSwitch(undefined);
    setSwitchDueDate(null);
    setPoE(undefined);
    setIPRange("");
    setNewlyBuilt(false);
    setOrderNote("");
    setFloorPlanFile(null);
    setStatus(API_I.Status.NEW);
    setProducts([]);
  }

  const uploadFile = (file: File, callback: (directusFile: IFile) => void) => {
    DirectusGateway.uploadFile(file, callback);
  }

  const handleClickComplete = () => {
    if (order) {
      saveOrder();
    } else {
      createOrder();
    }
  }

  const setStore = (store: IStore) => {
    store.name && setStoreName(store.name);
    store.address && setStoreAddress(store.address);
    store.city && setStoreCity(store.city);
    store.zip && setStoreZipCode(store.zip);
    store.internal_id && setStoreKardex(store.internal_id.toString());
    store.group_name && setStoreGroupName(store.group_name);
    store.country && setStoreCountry(store.country);
    store.store_responsible && setStoreResponsibleName(store.store_responsible);
    store.store_responsible_phone && setStoreResponsiblePhone(store.store_responsible_phone);
  }

  const handleNavigation = (n: number) => {
    const newStage = stage + (n);
    setStage(newStage);
    if (modal) {
      if (wrapperRef && wrapperRef.current)
        wrapperRef.current.parentElement?.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  const updateProduct = (product: I.OrderItem, quantity: number) => {
    const newProducts: I.OrderItem[] = Object.assign([], products);
    const updatedProduct = Object.assign({}, product, { quantity });
    const productIndex = newProducts.findIndex(p => p.product_id === product.product_id);
    newProducts[productIndex] = updatedProduct;
    setProducts(newProducts);
  }

  const customerRplc = (text: string) => text.replace("<customer>", selectedCustomer?.name ?? "Customer");

  const updateZindex = function(element:string, show:boolean){
     //@ts-ignore
    document.getElementById(element).style.zIndex = show ? 1000 : null;
  }

  //#region reusable elements
  const EslElements =
    <>
      <GridRow id="ESLServer">
        <Label>{T.ESL_SERVER}</Label>
        <NewDropdown value={ESLServer ? ESLServer : T.CHOOSE_ESL_SERVER} handleOnChange={(value: string) => setESLServer(value as any as API_I.ESLServer)} options={[...Object.values(API_I.ESLServer)].map((option) => { return {key:option,value:mergeDataToText(E[option])} })}  width={300} />        
        <DatePicker value={ESLServerDueDate} onCalendarOpen={()=> updateZindex("ESLServer",true)} onCalendarClose={()=> updateZindex("ESLServer",false)} onChange={(date: Date) => setESLServerDueDate(addHours(date,12))} format="dd.MM.yyyy" dayPlaceholder="DD" monthPlaceholder="MM" yearPlaceholder="YYYY" calendarIcon={<CalendarIcon />} />
      </GridRow>
      <Seperator />

      {modal &&
        <>
          <GridRow id="ESLDeliveryDate">
            <Label>{T.ESL_DELIVERY_DATE}</Label>
            <DatePicker value={ESLDeliveryDate} onCalendarOpen={()=> updateZindex("ESLDeliveryDate",true)} onCalendarClose={()=> updateZindex("ESLDeliveryDate",false)} onChange={(date: Date) => setESLDeliveryDate(addHours(date,12))} format="dd.MM.yyyy" dayPlaceholder="DD" monthPlaceholder="MM" yearPlaceholder="YYYY" calendarIcon={<CalendarIcon />} />
          </GridRow>
          <Seperator />
        </>
      }
    </>

  const SwitchAndPoE = <>
    <GridRow id="Switch">
      <Label>{T.SWITCH}</Label>
      <Dropdown value={_switch ? _switch : T.CHOOSE_SWITCH} handleOnChange={(value: string) => setSwitch(value as any as API_I.Switch)} options={[...Object.values(API_I.Switch)]} enumTranslations={E} width={350} />
      <DatePicker value={switchDueDate} onCalendarOpen={()=> updateZindex("Switch",true)} onCalendarClose={()=> updateZindex("Switch",false)} onChange={(date: Date) => setSwitchDueDate(addHours(date,12))} format="dd.MM.yyyy" dayPlaceholder="DD" monthPlaceholder="MM" yearPlaceholder="YYYY" calendarIcon={<CalendarIcon />} />
    </GridRow>
    <Seperator />

    <FlexRow>
      <Label>{T.PoE}</Label>
      <Dropdown value={PoE ? PoE : T.CHOOSE_POE} handleOnChange={(value: string) => setPoE(value as any as API_I.PoE)} options={[...Object.values(API_I.PoE)]} enumTranslations={E} width={320} />
    </FlexRow>
    <Seperator />
  </>

  const OrderedBy = <>
    <LabelInputPair label={T.ORDERED_BY} value={orderedBy} onChange={(value: string) => setOrderedBy(value)} />
    <LabelInputPair label={T.ORDERED_BY_EMAIL} value={orderedByEmail} onChange={(value: string) => setOrderedByEmail(value)} />
    <Seperator />
  </>

  const Address = <>
    <LabelInputPair label={T.STORE_NAME} value={storeName} onChange={(value: string) => setStoreName(value)} />
    <LabelInputPair label={T.ADDRESS} value={storeAddress} onChange={(value: string) => setStoreAddress(value)} />
    <LabelInputPair label={T.ZIP_CODE} value={storeZipCode} onChange={(value: string) => setStoreZipCode(value)} />
    <LabelInputPair label={T.CITY} value={storeCity} onChange={(value: string) => setStoreCity(value)} />
    <LabelInputPair label={T.KARDEX} value={storeKardex} onChange={(value: string) => setStoreKardex(value)} />
    <LabelInputPair label={T.COUNTRY} value={storeCountry} onChange={(value: string) => setStoreCountry(value)} />
    <LabelInputPair label={T.STORE_RESPONSIBLE} value={storeResponsibleName} onChange={(value: string) => setStoreResponsibleName(value)} />
    <LabelInputPair label={T.PHONE} value={storeResponsiblePhone} onChange={(value: string) => setStoreResponsiblePhone(value)} />
    <Seperator />
  </>

  const Numbers = <>
    <LabelInputPair label={T.IO_NUMBER} value={internalOrderNumber} onChange={(value: string) => setInternalOrderNumber(value)} />
    <LabelInputPair label={T.INC_NUMBER} value={internalNotificationNumber} onChange={(value: string) => setInternalNotificationNumber(value)} />
    <LabelInputPair label={selectedCustomer ? T.CUSTOMER_NUMBER.replace("<customer>", selectedCustomer.name) : T.CUSTOMER_NUMBER} value={customerCaseNumber} onChange={(value: string) => setCustomerCaseNumber(value)} />
    <LabelInputPair label={servicePartner ? T.SERVICE_PARTNER_NUMBER.replace("<service_partner>", servicePartner.name) : T.SERVICE_PARTNER_NUMBER} value={servicePartnerCaseNumber} onChange={(value: string) => setServicePartnerCaseNumber(value)} />
    <LabelInputPair label={T.EW_NUMBER} value={eastWestCaseNumber} onChange={(value: string) => setEastWestCaseNumber(value)} />
    <Seperator />
  </>

  const Products = products.map((product) => (
    <ProductInput key={product.product_id} product={product} updateProduct={updateProduct} />
  ));

  const APStore = <>
    <LabelInputPair numbersOnly label={T.STORE_AREA} value={storeSize} onChange={(value: string) => setStoreSizeAndUpdateAPCount(value)} />
    <Seperator />
    <LabelInputPair numbersOnly disabled label={T.AP_COUNT} value={APCount} onChange={() => { }} />
    <Seperator />
  </>


  const APDate = <>
    <FlexRow id="APDueDate">
      <Label>{mergeDataToText(T.AP_DUE_DATE)}</Label>
      <DatePicker value={APDueDate} onCalendarOpen={()=> updateZindex("Switch",true)} onCalendarClose={()=> updateZindex("Switch",false)} onChange={(date: Date) => setAPDueDate(addHours(date,12))} format="dd.MM.yyyy" dayPlaceholder="DD" monthPlaceholder="MM" yearPlaceholder="YYYY" calendarIcon={<CalendarIcon />} />
    </FlexRow>
    <Seperator />
  </>
  //#endregion


  return isMobile && !isTablet ? (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <UnDrawUseBrowser color={theme.colors.blue} />
      <Label style={{ fontSize: 24, textAlign: 'center' }}>{T.MESSAGE_ON_MOBILE}</Label>
    </div>
  ) : edit ? (
    // ========== EDIT EXISTING ORDER ==========
    <Wrapper ref={wrapperRef}>
      {stage !== 2 && (
        <>
          <Label style={{ alignSelf: 'center', fontSize: 32 }}>{orderType ? E[orderType] : "N/A"}</Label>

          {Address}
          {Numbers}
          {OrderedBy}

          <UploadFloorPlan floorPlanFile={floorPlanFile} uploadFile={(files: FileList) => { uploadFile(files[0], (directusFile: IFile) => { setFloorPlanFile(directusFile); }) }} /><Seperator />

          {orderType === API_I.OrderType.LABEL_ORDER ? (
            // ========== LABEL ORDER ========== //
            <>
              <LabelInputPair inputFieldMaxWidth label={T.DELIVERY_DATE_LABEL} value={deliveryDate} onChange={(value: string) => setDeliveryDate(value)} /><Seperator />

              {Products}

              <FlexRow style={{ alignItems: 'flex-start', gap: 16 }}>
                <Label>{T.ORDER_NOTE}</Label>
                <Textarea value={orderNote} handleOnChange={(value: string) => setOrderNote(value)} style={{ flex: 1 }} />
              </FlexRow>
            </>
          ) : orderType === API_I.OrderType.FULL_INSTALLATION ? (
            // ========== FULL INSTALLATION ORDER ========== //
            <>
              <LabelInputPair inputFieldMaxWidth label={customerRplc(T.DELIVERY_DATE_INSTALLATION)} value={deliveryDate} onChange={(value: string) => setDeliveryDate(value)} /><Seperator />

              <LabelInputPair numbersOnly label={T.STORE_AREA} value={storeSize} onChange={(value: string) => setStoreSizeAndUpdateAPCount(value)} /><Seperator />
              <LabelInputPair numbersOnly disabled label={T.AP_COUNT} value={APCount} onChange={() => { }} /><Seperator />
              
              {APDate}
              {Products}
              {EslElements}
              {SwitchAndPoE}

              <FlexRow>
                <Label>{T.NEWLY_BUILT}</Label>
                <Dropdown value={newlyBuilt.toString()} handleOnChange={(value: string) => setNewlyBuilt(value === "true")} options={["true", "false"]} enumTranslations={E} width={180} />
              </FlexRow><Seperator />

              <FlexRow style={{ alignItems: 'flex-start', gap: 16 }}>
                <Label >{T.ORDER_NOTE_SWITCH_SCENARIO}</Label>
                <Textarea value={orderNote} handleOnChange={(value: string) => setOrderNote(value)} style={{ flex: 1 }} />
              </FlexRow>
            </>
          ) : orderType === API_I.OrderType.FULL_INSTALLATION_EXTRA_ESL ? (
            // ========== FULL INSTALLATION EXTRA ESL ORDER ========== //
            <>
              <LabelInputPair inputFieldMaxWidth label={customerRplc(T.DELIVERY_DATE_INSTALLATION)} value={deliveryDate} onChange={(value: string) => setDeliveryDate(value)} /><Seperator />

              <Dropdown value={existingInstallation ? existingInstallation : T.CHOOSE_EXISTING_INSTALLATION} handleOnChange={(value: string) => setExistingInstallation(value as any as API_I.ExistingInstallation)} options={[...Object.values(API_I.ExistingInstallation)]} enumTranslations={E} width={300} />

              <LabelInputPair numbersOnly label={T.STORE_AREA} value={storeSize} onChange={(value: string) => setStoreSizeAndUpdateAPCount(value)} /><Seperator />
              <LabelInputPair numbersOnly disabled label={T.AP_COUNT} value={APCount} onChange={() => { }} /><Seperator />

              {APDate}
              {Products}
              {EslElements}
              {SwitchAndPoE}

              <LabelInputPair label={T.IP_RANGE} value={IPRange} onChange={(value: string) => setIPRange(value)} /><Seperator />

              <FlexRow style={{ alignItems: 'flex-start', gap: 16 }}>
                <Label >{T.ORDER_NOTE_SWITCH_SCENARIO}</Label>
                <Textarea value={orderNote} handleOnChange={(value: string) => setOrderNote(value)} style={{ flex: 1 }} />
              </FlexRow>
            </>
          ) : orderType === API_I.OrderType.SPECIAL_INSTALLATION ? (
            // ========== SPECIAL INSTALLATION ORDER ========== //
            <>
              <LabelInputPair inputFieldMaxWidth label={customerRplc(T.DELIVERY_DATE_INSTALLATION)} value={deliveryDate} onChange={(value: string) => setDeliveryDate(value)} /><Seperator />

              <LabelInputPair numbersOnly label={T.AP_COUNT_PoE} value={APCount} onChange={(value: string) => setAPCount(value)} /><Seperator />

              {APDate}
              
              <LabelInputPair label={T.IP_RANGE_EXTRA} value={IPRange} onChange={(value: string) => setIPRange(value)} /><Seperator />

              {Products}
              {EslElements}

              <FlexRow style={{ alignItems: 'flex-start', gap: 16 }}>
                <Label >{T.ORDER_NOTE_SPECIAL}</Label>
                <Textarea value={orderNote} handleOnChange={(value: string) => setOrderNote(value)} style={{ flex: 1 }} />
              </FlexRow>
            </>
          ) : <></>}
        </>
      )}
      {stage === 2 && (
        <>
          {loading ? (
            <Loader color={theme.colors.blue} background={theme.colors.lightBlue} text="" />
          ) : (
            <OrderComleteWrapper>
              <Label style={{ fontSize: 32, paddingBottom: 40 }}>{T.ORDER_UPDATED}</Label>
              <UnDrawOrderDelivered color={theme.colors.blue} />
              <ButtonNavigationWrapper style={{ justifyContent: 'center', marginTop: 64 }}>
                <Button handleOnClick={() => cancelCallback()}>{T.OK}</Button>
              </ButtonNavigationWrapper>
            </OrderComleteWrapper>
          )}
        </>
      )}
      {stage !== 2 && (
        // ========== Navigation Buttons ========== //
        <ButtonNavigationWrapper style={{ justifyContent: 'center' }}>
          <Button useAlternativeStyle handleOnClick={() => {
            cancelCallback();
          }}>{T.CANCEL}</Button>
          <Button handleOnClick={() => {
            setStage(2);
            saveOrder();
          }}>{T.SAVE}</Button>
        </ButtonNavigationWrapper>
      )}
      <EmptySpace />
    </Wrapper>
  ) : (
    // ========== CREATE NEW ORDER ==========
    <Wrapper ref={wrapperRef}>
      {/* ========== SET THE CUSTOMER ========== */}
      {stage === 0 && (
        <>
          <Label style={{ alignSelf: 'center', fontSize: 32 }}>{T.ORDER_INTERFACE}</Label>

          <FlexRow style={{ marginTop: 32, alignSelf: 'center' }}>
            <Dropdown value={selectedCustomer ? selectedCustomer.name : T.CHOOSE_CUSTOMER} handleOnChange={(value: string) => setSelectedCustomer(customers.find(c => c.name === value))} options={customers.map(c => c.name)} width={330} />
          </FlexRow>
        </>
      )}
      {/* ========== STORE DETAILS AND NUMBERS ========== */}
      {stage === 1 && (
        <>
          <Label style={{ alignSelf: 'center', fontSize: 32 }}>{T.ORDER_INTERFACE}</Label>
          <StoreSearchbar placeholder={T.STORE_SEARCH} value={storeSearch} handleOnChange={(value: string) => setStoreSearch(value)} handleSelectStore={(store: IStore) => setStore(store)} searchResults={stores} style={{ alignSelf: 'center' }} />

          {Address}
          {Numbers}
          {OrderedBy}

          <FlexRow>
            <Dropdown value={orderType ? orderType : T.CHOOSE_ORDER_TYPE} handleOnChange={(value: string) => setOrderType(value as any as API_I.OrderType)} options={[...Object.values(API_I.OrderType)]} enumTranslations={E} width={330} />
          </FlexRow>

          <EmptySpace />
        </>
      )}
      {/* ========== ORDER DETAILS ========== */}
      {stage === 2 && (
        orderType === API_I.OrderType.LABEL_ORDER ? (
          // ========== LABEL ORDER ========== //
          <>
            <Label style={{ alignSelf: 'center', fontSize: 32 }}>{E[orderType]}</Label>
            <LabelInputPair inputFieldMaxWidth label={T.DELIVERY_DATE_LABEL} value={deliveryDate} onChange={(value: string) => setDeliveryDate(value)} /><Seperator />

            {Products}

            <FlexRow style={{ alignItems: 'flex-start', gap: 16 }}>
              <Label>{T.ORDER_NOTE}</Label>
              <Textarea value={orderNote} handleOnChange={(value: string) => setOrderNote(value)} style={{ flex: 1 }} />
            </FlexRow>
          </>
        ) : orderType === API_I.OrderType.FULL_INSTALLATION ? (
          // ========== FULL INSTALLATION ORDER ========== //
          <>
            <Label style={{ alignSelf: 'center', fontSize: 32 }}>{E[orderType]}</Label>
            <LabelInputPair inputFieldMaxWidth label={customerRplc(T.DELIVERY_DATE_INSTALLATION)} value={deliveryDate} onChange={(value: string) => setDeliveryDate(value)} /><Seperator />

            <UploadFloorPlan floorPlanFile={floorPlanFile} uploadFile={(files: FileList) => { uploadFile(files[0], (directusFile: IFile) => { setFloorPlanFile(directusFile); }) }} /><Seperator />

            {APStore}
            {APDate}
            {Products}
            {EslElements}
            {SwitchAndPoE}

            <FlexRow>
              <Label>{T.NEWLY_BUILT}</Label>
              <Dropdown value={newlyBuilt.toString()} handleOnChange={(value: string) => setNewlyBuilt(value === "true")} options={["true", "false"]} enumTranslations={E} width={180} />
            </FlexRow><Seperator />

            <FlexRow style={{ alignItems: 'flex-start', gap: 16 }}>
              <Label >{T.ORDER_NOTE_SWITCH_SCENARIO}</Label>
              <Textarea value={orderNote} handleOnChange={(value: string) => setOrderNote(value)} style={{ flex: 1 }} />
            </FlexRow>
          </>
        ) : orderType === API_I.OrderType.FULL_INSTALLATION_EXTRA_ESL ? (
          // ========== FULL INSTALLATION EXTRA ESL ORDER ========== //
          <>
            <Label style={{ alignSelf: 'center', fontSize: 32 }}>{E[orderType]}</Label>
            <LabelInputPair inputFieldMaxWidth label={customerRplc(T.DELIVERY_DATE_INSTALLATION)} value={deliveryDate} onChange={(value: string) => setDeliveryDate(value)} /><Seperator />

            <FlexRow>
              <Dropdown value={existingInstallation ? existingInstallation : T.CHOOSE_EXISTING_INSTALLATION} handleOnChange={(value: string) => setExistingInstallation(value as any as API_I.ExistingInstallation)} options={[...Object.values(API_I.ExistingInstallation)]} enumTranslations={E} width={300} />
              <UploadFloorPlan floorPlanFile={floorPlanFile} uploadFile={(files: FileList) => { uploadFile(files[0], (directusFile: IFile) => { setFloorPlanFile(directusFile); }) }} />
            </FlexRow><Seperator />

            {APStore}
            {APDate}
            {Products}
            {EslElements}
            {SwitchAndPoE}

            <LabelInputPair label={T.IP_RANGE} value={IPRange} onChange={(value: string) => setIPRange(value)} /><Seperator />

            <FlexRow style={{ alignItems: 'flex-start', gap: 16 }}>
              <Label >{T.ORDER_NOTE_SWITCH_SCENARIO}</Label>
              <Textarea value={orderNote} handleOnChange={(value: string) => setOrderNote(value)} style={{ flex: 1 }} />
            </FlexRow>
          </>
        ) : orderType === API_I.OrderType.SPECIAL_INSTALLATION ? (
          // ========== SPECIAL INSTALLATION ORDER ========== //
          <>
            <Label style={{ alignSelf: 'center', fontSize: 32 }}>{E[orderType]}</Label>
            <LabelInputPair inputFieldMaxWidth label={T.DELIVERY_DATE_INSTALLATION.replace("<customer>", selectedCustomer?.name ?? "Customer")} value={deliveryDate} onChange={(value: string) => setDeliveryDate(value)} /><Seperator />

            <UploadFloorPlan floorPlanFile={floorPlanFile} uploadFile={(files: FileList) => { uploadFile(files[0], (directusFile: IFile) => { setFloorPlanFile(directusFile); }) }} /><Seperator />

            <LabelInputPair numbersOnly label={T.AP_COUNT_PoE} value={APCount} onChange={(value: string) => setAPCount(value)} /><Seperator />
            
            {APDate}

            <LabelInputPair label={T.IP_RANGE_EXTRA} value={IPRange} onChange={(value: string) => setIPRange(value)} /><Seperator />

            {Products}
            {EslElements}

            <FlexRow style={{ alignItems: 'flex-start', gap: 16 }}>
              <Label >{T.ORDER_NOTE_SPECIAL}</Label>
              <Textarea value={orderNote} handleOnChange={(value: string) => setOrderNote(value)} style={{ flex: 1 }} />
            </FlexRow>
          </>
        ) : <></>
      )}
      {stage === 3 && (
        <>
          {loading ? (
            <Loader color={theme.colors.blue} background={theme.colors.lightBlue} text="" />
          ) : (
            <OrderComleteWrapper>
              <Label style={{ fontSize: 32, paddingBottom: 40 }}>{T.ORDER_CREATED}</Label>
              <UnDrawOrderDelivered color={theme.colors.blue} />
              <ButtonNavigationWrapper style={{ justifyContent: 'center', marginTop: 64 }}>
                {modal ? (
                  <Button handleOnClick={() => cancelCallback()}>{T.OK}</Button>
                ) : (
                  <Button handleOnClick={() => resetState()}>{T.CREATE_NEW_ORDER}</Button>
                )}
              </ButtonNavigationWrapper>
            </OrderComleteWrapper>
          )}
        </>
      )}
      {stage !== 3 && (
        <ButtonNavigationWrapper style={{ justifyContent: 'center' }}>
          {!modal ? (
            <>
              {stage !== 1 && (
                <Button useAlternativeStyle handleOnClick={() => handleNavigation(-1)}>{T.BACK}</Button>
              )}
              {stage === 1 ? (
                <Button disabled={orderType === undefined} handleOnClick={() => handleNavigation(1)}>{T.NEXT}</Button>
              ) : (
                <Button handleOnClick={() => {
                  handleNavigation(1);
                  handleClickComplete();
                }}>{T.COMPLETE}</Button>
              )}
            </>
          ) : (
            <>
              <Button useAlternativeStyle handleOnClick={() => {
                cancelCallback();
              }}>{T.CANCEL}</Button>
              {((stage !== 0) && (stage !== 1 && customers.length === 1)) && (
                <Button useAlternativeStyle handleOnClick={() => handleNavigation(-1)}>{T.BACK}</Button>
              )}
              {stage === 0 ? (
                <Button disabled={selectedCustomer === undefined} handleOnClick={() => handleNavigation(1)}>{T.NEXT}</Button>
              ) : stage === 1 ? (
                <Button disabled={orderType === undefined} handleOnClick={() => handleNavigation(1)}>{T.NEXT}</Button>
              ) : (
                <Button handleOnClick={() => {
                  handleNavigation(1);
                  handleClickComplete();
                }}>{T.COMPLETE}</Button>
              )}
            </>
          )}
        </ButtonNavigationWrapper>
      )}
      <EmptySpace />
    </Wrapper>
  );
}

//#region JSX functional elements
const LabelInputPair = ({ label, value, onChange, inputFieldMaxWidth, numbersOnly = false, disabled = false }: I.LabelInputPairProps) => (
  <FlexRow style={{ gap: 16 }}>
    <Label>{label}</Label>
    <Inputfield
      disabled={disabled}
      numbersOnly={numbersOnly}
      value={value}
      handleOnChange={(value: string) => onChange(value)}
      style={{ flexGrow: inputFieldMaxWidth ? 1 : 0 }}
    />
  </FlexRow>
);
const ProductInput = ({ product, updateProduct }: I.ProductInputProps) => (
  <>
    <GridRow>
      <Label>{product.product_name}</Label>
      <Inputfield
        numbersOnly
        value={product.quantity.toString()}
        handleOnChange={(value: string) => updateProduct(product, value)}
        style={{ width: 100 }}
      />
      <Label>{product.product_short_description}</Label>
    </GridRow>
    <Seperator />
  </>
);

const UploadFloorPlan = ({ uploadFile, floorPlanFile }: I.UploadFloorPlanProps) => (
  <Dropzone onDrop={async (acceptedFiles) => {
    const files = acceptedFiles as any as FileList;
    if (files && files.length > 0) {
      uploadFile(files);
    }
  }}>
    {({ getRootProps, getInputProps }) => (
      <Dropfield>
        <div {...getRootProps()} style={{ display: 'flex', flexDirection: 'row', gap: 16 }}>
          <input {...getInputProps()} />
          <UploadFile />
          <Label>{floorPlanFile ? floorPlanFile.filename_download : T.UPLOAD_FLOOR_PLAN}</Label>
        </div>
      </Dropfield>
    )}
  </Dropzone>
);
//#endregion

export default NewOrder;