import React from 'react';
import Main from './Main';
import Logger, { logLevel } from './utils/Logger';
import { WindowLogger } from './utils/WindowLogger';

interface EastWestSettings {
  logLevel: number;
}

declare global {
  interface Window {
    eastWestSettings: EastWestSettings
  }
}

Logger.addLogPlugin(new WindowLogger());
Logger.level = (window.location.href.indexOf("localhost") === -1)?logLevel.LOG_ERROR:logLevel.LOG_ALL;

function App() {
  return (
    <div className="App">
        <Main/>
    </div>
  );
}

export default App;
