import { ESLServer, ExistingInstallation, OrderType, PoE, Switch } from "../interfaces/api_interfaces"

/*
 * These Translations are used throughout the Order Interface labels, buttons, etc.
 */
const T = {
  MESSAGE_ON_MOBILE: "Bestillingsiden er desværre ikke tilgængelig på mobiltelefon. Benyt venligst en computer eller tablet.",
  ORDER_INTERFACE: "BESTILLINGSARK",
  
  // INTERFACE
  LOGGED_IN_AS: "Logget ind som: ",
  LOGOUT: "Log ud",
  BACK: "Tilbage",
  NEXT: "Videre",
  COMPLETE: "Indsend",
  SAVE: "Gem",
  CANCEL: "Annuller",
  OK: "OK",

  // CHOOSE CUSTOMER
  CHOOSE_CUSTOMER: "Vælg kunde",

  // STORE DETAILS
  STORE_NAME: "Butiksnavn",
  STORE_SEARCH: "Søg efter butik",
  ADDRESS: "Adresse",
  ZIP_CODE: "Postnummer",
  CITY: "By",
  KARDEX: "Kardex nr.",
  COUNTRY: "Land",
  STORE_RESPONSIBLE: "Butiks ansvarlige",
  PHONE: "Butiks ansvarlige tlf. nummer",
  
  // CASE DETAILS
  IO_NUMBER: "IO nr.",
  INC_NUMBER: "INC nr.",
  CUSTOMER_NUMBER: "<customer> nr.",
  SERVICE_PARTNER_NUMBER: "<service_partner> sagsnr.",
  EW_NUMBER: "EW projektnr.",

  // ORDER DETAILS
  ORDERED_BY: "Bestilt af",
  ORDERED_BY_EMAIL: "Bestillers email adresse",
  CHOOSE_ORDER_TYPE: "Vælg bestillingstype",
  DELIVERY_DATE_LABEL: "Ønsket leveret inden",
  DELIVERY_DATE_INSTALLATION: "<customer> ønsker alt leveret og installeret inden",
  ORDER_NOTE: "Bemærkninger",
  ORDER_NOTE_SWITCH_SCENARIO: "Bemærkninger og beskrivelse af switch scenarie",
  ORDER_NOTE_SPECIAL: "Beskriv omstændighederne vedrørende denne særbestilling",
  CHOOSE_EXISTING_INSTALLATION: "Vælg eksisterende installation",
  CHOOSE_ESL_SERVER: "Vælg ESL server",
  CHOOSE_SWITCH: "Vælg Switch",
  CHOOSE_POE: "Vælg PoE",
  STORE_AREA: "Butiksareal - m2",
  AP_COUNT: "Antal Access Points",
  AP_COUNT_PoE: "Antal Access Points PoE",
  AP_INSTALLATIONS: "AP installationspakker",
  AP_DUE_DATE: "<service_partner> kabler og installerer AP i butikken",
  ESL_SERVER: "ESL server",
  ESL_DELIVERY_DATE: "ESL leverings dato",
  SWITCH: "Switch",
  PoE: "PoE",
  NEWLY_BUILT: "Nyt byggeri?",
  IP_RANGE: "IP range til AP'er",
  IP_RANGE_EXTRA: "IP range til ekstra AP'er",
  UPLOAD_FLOOR_PLAN: "Upload Floor Plan",
  
  CUSTOMER: "Kunde",
  SERVICE_PARTNER: "Service partner",

  // ACTIONS
  CREATE_NEW_ORDER: "Lav en ny ordre",
  ORDER_CREATED: "Din ordre blev oprettet!",
  ORDER_UPDATED: "Din ordre blev opdateret!"
}

/*
 * These "Enum" Translations are specific for enum values. As we are setting the value and options for Dropdowns to the exact values from Directus for a given field,
 * we need to "translate" these enum values to a "pretty name".
 */
// ENUM TRANSLATIONS
let ENUM_TRANSLATIONS: { 
  [key: string]: string; 
} = {}

// ORDER TYPE
ENUM_TRANSLATIONS[T.CHOOSE_ORDER_TYPE] = T.CHOOSE_ORDER_TYPE;
ENUM_TRANSLATIONS[OrderType.LABEL_ORDER] = "Etiketbestilling";
ENUM_TRANSLATIONS[OrderType.FULL_INSTALLATION] = "Fuld installation";
ENUM_TRANSLATIONS[OrderType.FULL_INSTALLATION_EXTRA_ESL] = "Fuld installation af ekstra ESL linje";
ENUM_TRANSLATIONS[OrderType.SPECIAL_INSTALLATION] = "Særbestilling";

// EXISTING INSTALLATION
ENUM_TRANSLATIONS[T.CHOOSE_EXISTING_INSTALLATION] = T.CHOOSE_EXISTING_INSTALLATION;
ENUM_TRANSLATIONS[ExistingInstallation.DELFI] = "DELFI";
ENUM_TRANSLATIONS[ExistingInstallation.PRICER] = "PRICER";

// ESL SERVER
ENUM_TRANSLATIONS[T.CHOOSE_ESL_SERVER] = T.CHOOSE_ESL_SERVER;
ENUM_TRANSLATIONS[ESLServer.ONE_PIECE_STORE_INSTALLS] = "<customer> installerer image og leverer";//"1 stk. - Butik installerer image";//
ENUM_TRANSLATIONS[ESLServer.ONE_PIECE_PRE_INSTALLED] = "<service_partner> installerer image og leverer";//"1 stk. med installeret image";//

// SWITCH
ENUM_TRANSLATIONS[T.CHOOSE_SWITCH] = T.CHOOSE_SWITCH;
ENUM_TRANSLATIONS[Switch.USE_EXISTING] = "Brug eksisterende";
ENUM_TRANSLATIONS[Switch.NEW_SWITCH] = "Ny switch - se beskrivelse";

// POE
ENUM_TRANSLATIONS[T.CHOOSE_POE] = T.CHOOSE_POE;
ENUM_TRANSLATIONS[PoE.IS_INSTALLED] = "Er installeret";
ENUM_TRANSLATIONS[PoE.NOT_TO_BE_INSTALLED] = "Skal ikke installeres - brug 230V";
ENUM_TRANSLATIONS[PoE.TO_BE_INSATLLED] = "Bliver installeret med ny switch";

// NEWLY BUILT
ENUM_TRANSLATIONS["true"] = "Ja";
ENUM_TRANSLATIONS["false"] = "Nej";

export const E = ENUM_TRANSLATIONS;

export default T;