import React from 'react';
import styled from 'styled-components';

import NewOrder from '../new-order/NewOrder';

import { IOrder } from '../models/interfaces/IOrder';
import { IOrderItem } from '../models/interfaces/IOrderItem';

import { DialogWrapper } from '../utils/StyledComponents';
import Logger from '../utils/Logger';

const OrderDataBackground = styled.div`
  width: 866px;
  height: 80vh;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.11);

  background-color: #f0f9ff;
  overflow: auto;
`

export interface OrderDataProps {
  dataUpdated: Function;
  hideModal: Function;
  orderId: number;
  order?: IOrder;
  orderItems?: IOrderItem[];
}

interface OrderState {
}

class OrderData extends React.Component<OrderDataProps, OrderState> {
  wrapperRef: React.RefObject<HTMLDivElement>;

  constructor(props: OrderDataProps) {
    super(props);

    this.wrapperRef = React.createRef();
    // this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    // document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    // document.removeEventListener("mousedown", this.handleClickOutside);
  }

  // handleClickOutside(event: MouseEvent) {
  //   if (this.wrapperRef && !this.wrapperRef.current?.contains(event.target as Node)) {
  //     this.props.dataUpdated();
  //   }
  // }

  // hideDialog() {
  //   this.props.dataUpdated();
  //   this.props.hideModal();
  // }

  // hideDialog() {
  //   this.props.dataUpdated();
  //   this.props.hideModal();
  // }

  render() {
    return (
      <DialogWrapper>
        <OrderDataBackground ref={this.wrapperRef}>
          <NewOrder
            order={this.props.order}
            orderItems={this.props.orderItems}
            orderUpdatedSuccessfullyCallback={() => {
              Logger.log("orderUpdatedSuccessfullyCallback");
              this.props.dataUpdated();
            }}
            cancelCallback={() => this.props.hideModal()}
            modal={true}
          />
        </OrderDataBackground>
      </DialogWrapper >
    )
  };
};

export default OrderData;