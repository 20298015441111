import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import AlertDialog from '../components/AlertDialog';
import { FilledButton } from '../components/Buttons';
import DirectusGateway from '../gateways/DirectusGateway';
import { IOrder } from '../models/interfaces/IOrder';
import { formatDateString } from '../utils/Dates';
import { Filters, OrderStatus } from '../utils/Enums';
import { DownloadIcon, FoldIcon } from '../utils/icons';
import { Column } from '../utils/StyledComponents';
import OrderData from './OrderData';
import TaskList from './tasks/TaskList';
import { prettifyOrderType } from '../utils/Strings';

interface LabelProps {
  isArchived?: boolean;
}


const OrderWrapper = styled.div`
  width: 100%;
  margin: 14px 0;
`

const OrderHeader = styled.div`
  position: relative;
  padding: 16px 16px 16px 24px;
  background-color: #e3f4fd;
  cursor: pointer;
  font-size: 14px;
  overflow: hidden;
`

const CategoryData = styled.span`
  font-size: 16px;
`

const CategoryTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px;
`

const OrderDetails = styled.div`
  padding: 24px ${isMobile ? "16px" : "40px"} ;
  border: solid 1px #e3f4fd;
  border-top: none;
  background-color: #f0f9ff;
`
const OrderSummary = styled.div`
  display: ${isMobile ? "block" : "flex"};
  justify-content: space-between;
  padding: 0px;
  padding-bottom: 30px;
  border-bottom: solid 1px #d6e5ef;
`

const OrderSummaryLeft = styled.div`  
  display: ${isMobile ? "block" : "flex"};
`

const OrderSummaryRight = styled.div`  
  display: ${isMobile ? "block" : "flex"};
  margin-top: 8px;
  div {
    display: inline-block;
    margin: ${isMobile ? "0 8px 0 0" : "0 0 0 8px"};
  }
`

const SummaryBlock = styled.div`
  display:inline-block;
  margin-right: 10px;
`

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Row = styled.div`
  display: block;
`

const ArchiveBtn = styled.div`
  box-sizing: border-box;
  margin-left: 16px;
  height: 35px; 
  font-size: 14px;
  padding: 8px 16px 8px 16px;
  color: #ffffff;
  background-color: #0b5d91;
  cursor: pointer;
`

const NewLabel = styled.div<LabelProps>`
  position: absolute;
  font-size:11px;
  line-height: 11px;
  top: 5px;
  left: -40px;
  width: 120px;
  height: 14px;
  text-align: center;
  background-color: ${props => (props.isArchived) ? "#999" : "#d83"};
  color: #fff;
  display: inline-block;
  transform: rotate(-30deg);
`

const FoldWrapper = styled.div<LabelProps>`
  position: absolute;
  right: 15px;
  top:83px;
`

const FloorplanLink = styled.a` 
display: block;
  margin-top:${isMobile ? "8px" : "0"};
`
export interface OrderProps {
  orderData: IOrder;
  dataUpdated: Function;
  filter: string;
}

interface OrderState {
  showDetails: boolean;
  showAlert: boolean;
  showOrderData: boolean;
}

class Order extends React.Component<OrderProps, OrderState> {
  state: OrderState = {
    showDetails: false,
    showAlert: false,
    showOrderData: false
  };

  toggleShowDetails() {
    this.setState({
      showDetails: !this.state.showDetails
    });
  }

  closeAlert() {
    this.setState({
      showAlert: false
    });
  }

  async archive() {
    const order = this.props.orderData;
    if (order.processes?.length) {
      let done = this.getNumberOfDones();

      if (done !== order.processes.length) {
        this.setState({
          showAlert: true
        });
      } else {
        await DirectusGateway.updateOrderStatus(order.id, OrderStatus.Archived);
        this.props.dataUpdated();
      }
    }
  }

  async unarchive() {
    const order = this.props.orderData;
    await DirectusGateway.updateOrderStatus(order.id, OrderStatus.InProgress);
    this.props.dataUpdated();
  }

  getNumberOfDones() {
    let done = 0;
    this.props.orderData.processes.forEach((item) => {
      if (item.status === "done") {
        done++;
      }
    });
    return done;
  }

  redirectToFlorplan(file: any) {
    DirectusGateway.downloadDocument(file);
  }

  async dataUpdated() {
    this.props.dataUpdated();
  }

  orderDataChanged() {
    // this.setState({ showOrderData: false });    
    this.props.dataUpdated();
  }

  render() {
    const order = this.props.orderData;
    let flow = "0/0";

    if (order.processes?.length) {
      let done = this.getNumberOfDones();
      flow = done + "/" + order.processes.length
    }
    const isArchived = order.status === OrderStatus.Archived;

    return (
      <>
        <OrderWrapper >
          <OrderHeader onClick={() => this.toggleShowDetails()}>
            {isMobile ?
              <>
                <Row><CategoryTitle>Date of order</CategoryTitle> {formatDateString(order.date_created)}</Row>
                <Row><CategoryTitle>AP installation</CategoryTitle> {formatDateString(order.ap_due_date)}</Row>
                <Row><CategoryTitle>ESL delivery</CategoryTitle> {formatDateString(order.esl_delivery_date)}</Row>
                <Row><CategoryTitle>Store ID</CategoryTitle>  {order.store_internal_id}</Row>
                <Row><CategoryTitle>Store name</CategoryTitle> {this.props.orderData.store_name}</Row>
                <Row><CategoryTitle>Ordertype</CategoryTitle> {prettifyOrderType(order.order_type)}</Row>
                <Row> <CategoryTitle>Progress</CategoryTitle> {flow}</Row>

                <FoldWrapper><FoldIcon active={this.state.showDetails} /></FoldWrapper>
              </>
              :
              <>
                <Column>{formatDateString(order.date_created)}</Column>
                <Column>{formatDateString(order.ap_due_date)}</Column>
                <Column>{formatDateString(order.esl_delivery_date)}</Column>
                <Column>{order.internal_order_number}</Column>
                <Column>{order.internal_notification_number}</Column>
                <Column>{order.customer_case_number}</Column>
                <Column>{order.store_internal_id}</Column>
                <Column>
                  {this.props.orderData.store_name}
                </Column>

                <Column>{prettifyOrderType(order.order_type)}</Column>
                <Column>{flow}</Column>

                <Column><FoldIcon active={this.state.showDetails} /></Column>
              </>
            }
            {order.status === OrderStatus.New &&
              <NewLabel>new</NewLabel>
            }
            {order.status === OrderStatus.Archived && this.props.filter === Filters.All &&
              <NewLabel isArchived={true}>archived</NewLabel>
            }
          </OrderHeader>
          {this.state.showDetails &&
            <OrderDetails>
              <OrderSummary>
                <OrderSummaryLeft>
                  <SummaryBlock>
                    <CategoryTitle>AP installation:</CategoryTitle> <CategoryData>{formatDateString(order.ap_due_date)}</CategoryData>
                  </SummaryBlock>
                  <SummaryBlock>
                    <CategoryTitle>ESL leveringsdato:</CategoryTitle> <CategoryData>{formatDateString(order.esl_server_due_date)}</CategoryData>
                  </SummaryBlock>
                </OrderSummaryLeft>
                <OrderSummaryRight>
                  {!isMobile &&
                    <FilledButton onClick={() => this.setState({ showOrderData: true })}><DownloadIcon /> Order data</FilledButton>
                  }
                  {order.floor_plan_file && (
                    <FloorplanLink
                      href={DirectusGateway.directusUrl+"/assets/" + order.floor_plan_file + "?download"}
                    >
                      <FilledButton><DownloadIcon /> Floor plan</FilledButton>
                    </FloorplanLink>
                  )}
                </OrderSummaryRight>
              </OrderSummary>
              <TaskList orderId={order.id} taskData={order.processes} dataUpdated={() => this.dataUpdated()} isArchived={isArchived} />
              <Footer>
                {isArchived ?
                  <ArchiveBtn onClick={() => { this.unarchive() }}>Unarchive</ArchiveBtn>
                  :
                  <ArchiveBtn onClick={() => { this.archive() }}>Archive</ArchiveBtn>
                }
              </Footer>
            </OrderDetails>
          }
        </OrderWrapper >
        {this.state.showAlert &&
          <AlertDialog title='Can’t archive' message='Complete the process to archive.' dialogClosed={() => this.closeAlert()} />
        }
        {this.state.showOrderData &&
          <OrderData
            order={order}
            orderItems={order.items}
            dataUpdated={this.orderDataChanged.bind(this)}
            orderId={order.id}
            hideModal={() => this.setState({ showOrderData: false })}
          />
        }
      </>
    )
  };
};

export default Order;